import React, {Component} from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom'
import TextField from '@material-ui/core/TextField';
import portalStyles from './affiliatesPortal.module.css'
import ActiveProjects from '../tables/activeProjects'
import Referrals from '../tables/referrals'
import _ from 'lodash';

class AffiliatesPortal extends Component {

  state = {
    incentives: [],
    referrals: []
  }

  componentDidMount() {
    const accessToken = window.sessionStorage.getItem('token')
    axios
    .post(
      'https://uploadrequestforquote.azurewebsites.net/api/OnAffiliateAccess?clientId=apim-APIMGTSGL',
      //'http://localhost:7071/api/OnAffiliateAccess',
      {},
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        timeout: 30000
      }
    )
    .then(response => {
      console.log(response);
      this.setState({incentives: response.data.incentiveItems, referrals: response.data.referralItems})
    })
    .catch(() => {
      // toggleSnackbar({ open: true, message:  'This machine currently has problems communicating to the server. Please try again later.', success: false });
    })
    // .finally(() => {
    //   setState({
    //     ...state,
    //     showLoading: { value: false },
    //   });
    // });
}

    render(){
      const {referrals} = this.state;
      let filteredReferrals = _.filter(referrals, { 'status': 'Unpaid' }); 
      let currentEarnings = 0;
      const earnings  = []
      filteredReferrals.forEach(item => {
        return earnings.push(
          item.proposedAmount
        )
      })
      earnings.forEach(item => {
       currentEarnings += parseFloat(item.replace(/,/g, ''));
      })

      var currentDate = new Date().toLocaleDateString();
        return(
            <div className={portalStyles.body}>
            <div className={portalStyles.content}>
              <div className={portalStyles.heading}>
                <h2>AFFILIATE PORTAL</h2>
              </div>

              <div className={portalStyles.detailsContainer}>
                <Link className={portalStyles.link}>
                  Guide to Success
                  </Link>
                </div>
    
              <div className={portalStyles.titleContainer}>
                <div className={portalStyles.titles}>
                  <div className={portalStyles.details}>
                    <strong>CURRENT EARNINGS: Php {currentEarnings.toLocaleString('en-US')}  </strong><span>( as of {currentDate}) </span>
                  </div>
                </div>
              </div>

    
                  <div className={portalStyles.detailsContainer}>
                      <ActiveProjects incentives={this.state.incentives}></ActiveProjects>
                  </div>

                  <div className={portalStyles.detailsContainer}>
                     <Referrals referrals={this.state.referrals}></Referrals>
                  </div>

                  {/* <div className={portalStyles.detailsContainer}>
                    <div className={portalStyles.details}>
                      <strong>CREDENTIALS</strong>
                    </div>
                    <div style={{display: 'flex'}}>
                    <TextField
                        style={{marginLeft: 100, marginRight: 30, marginTop: 30}}
                        disabled
                        id='outlined-required'
                        label='Username'
                        variant='outlined'
                        value={"belleborlongan@gmail.com"}
                      />
                      <div style={{marginTop: 45}}>
                        <Link style={{marginTop: 20}}>Change Password</Link>
                      </div>
                    </div>
                  </div> */}
            </div>
          </div>
        )    
    }
}

export default AffiliatesPortal;