import React, { useState } from 'react';
import axios from 'axios';
import SGLButton from '../../components/common/Button';
import {  TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { withTheme, withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import loginStyles from './login.module.css';
import { makeStyles } from '@material-ui/core';

const styleClasses = makeStyles({
  root: {
  width: 300,
  height: 40,
  background: "transparent linear-gradient(100deg, #61893c 0%, #40c267 46%, #87c240 100%) 0% 0% no-repeat padding-box",
  borderRadius: 4,
  color: "whitesmoke",
  opacity: 1
  },
  button2: {
    width: 180,
    height: 40,
    background: "transparent linear-gradient(100deg, #61893c 0%, #40c267 46%, #87c240 100%) 0% 0% no-repeat padding-box",
    borderRadius: 4,
    color: "whitesmoke",
    opacity: 1
    }
});

const LoginPageContent = props => {
const {  onClose, classes } = props;
const buttonStyles = styleClasses();
let currentState = {};

const loginClickHandler = (state) => {
  let valid = true;

  if (
    !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.email.value)
  ) {
    valid = false;
    currentState = {
      ...currentState,
      email: { value: '', validation: 'Invalid email address.' },
    };
  }

  if (!state.password.value) {
    valid = false;
    currentState = {
      ...currentState,
      password: { value: '', validation: 'Please input your password.' },
    };
  }

  if (!valid) {
    setState({ ...state, ...currentState });
  }

  if (valid) {
    setState({
      ...state,
      showLoading: { value: true },
    });

    const dataParams = {
      Email: state.email.value,
      Password: state.password.value,
    }

    setTimeout(() => {
      axios
        .post(
           'https://uploadrequestforquote.azurewebsites.net/api/OnLoginReceived?code=X42C78zSmzvl7gYZs0evu5kydLA8Qx/v3Sm4Dlcmay8zYR/ttVicdg==&clientId=apim-APIMGTSGL',
          //'http://localhost:7071/api/OnLoginReceived',
          JSON.stringify(dataParams),
          {
            headers: {
              'Content-Type': 'application/json',
            },
            timeout: 30000,
            data: {}, //Note: Empty data is a work around for axios to prevent removing the content type header
          }
        )
        .then((response) => {
          console.log(response);
          if (response.status === 200){
            window.location = '/AffiliatesPortal';
            // window.location = '/AffiliatesPortalTest';
            sessionStorage.setItem("token", response.data.accessToken);
          }
        })
        .finally(() => {
          setState({
            ...state,
            showLoading: { value: false },
          });
        });
    }, 1000);
  }
};

const [state, setState] = useState({
    email: { value: '', validation: '' },
    password:  { value: '', validation: '' },
  });

  const {
      email,
      password
  } = state;
   
  return (
    <ClickAwayListener onClickAway={() => onClose()}>
    <div className={loginStyles.rootContainer}>
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={() => onClose()}
          style={{
           marginLeft: 330, 
          }}
        >
          <CloseIcon
            style={{
              fontSize: 20, marginTop: -10, color: "#9b9ea2f7"
            }}
          />
        </IconButton>
        <div  style={{marginTop: 10}}>
            <TextField
                style={{marginBottom: 30, width: 300}}
                required
                id='outlined-required'
                label='Username'
                variant='outlined'
                error={email.validation !== ''}
                helperText={email.validation}
                value={email.value}
                onChange={(e) =>
                    setState({
                    ...state,
                    email: { value: e.target.value, validation: '' },
                    })
                }
            />
        </div>
        <div>
            <TextField
                style={{marginBottom: 30, width: 300}}
                type="password"
                required
                id='outlined-required'
                label='Password'
                variant='outlined'
                error={password.validation !== ''}
                helperText={password.validation}
                value={password.value}
                onChange={(e) =>
                    setState({
                    ...state,
                    password: { value: e.target.value, validation: '' },
                    })
                }
            />
        </div>
      <div className={loginStyles.buttonsContainer}>
        <div className={loginStyles.loginButton}>

        <Button classes={{
          root: buttonStyles.root     
          }}
          onClick={() => loginClickHandler(state)}
          >
            LOGIN
        </Button>
       
        </div>
        <Link style={{ textDecoration: 'none', fontSize: 12 }} to=''>
         Forgot Password?
          </Link>
     <hr style={{border: "0.5px solid lightgrey"}}></hr>
      </div>
      <div className={loginStyles.registerButton}>
        <Link style={{ textDecoration: 'none' }} to='/Registration'>
          <Button
           classes={{
            root: buttonStyles.button2     
            }}
            onClick={() => onClose()}
          > BECOME AN AFFILIATE</Button>
          </Link>
        </div>
    </div>
    </ClickAwayListener>
  );
};

export default LoginPageContent;
