import React, {Component} from 'react';
import axios from 'axios';

import { TextField } from '@material-ui/core';
import SGLButton from '../../components/common/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

class ActivateModalContent extends Component {

    state = {
        masterPassword: "",
        projectName: "",
        userValidated: false,
        validations: {
            projectName: "",
            masterPassword: ""
          }
    }

    submitClickHandler = () => {    
      const dataParams = {
        password: this.state.masterPassword.value
      }

      axios
        .post(
          'https://uploadrequestforquote.azurewebsites.net/api/OnAuthenticationReceived?code=X42C78zSmzvl7gYZs0evu5kydLA8Qx/v3Sm4Dlcmay8zYR/ttVicdg==&clientId=apim-APIMGTSGL',
          //'http://localhost:7071/api/OnAuthenticationReceived',
          JSON.stringify(dataParams),
          {
            headers: {
              'Content-Type': 'application/json',
            },
            timeout: 30000,
            data: {}, //Note: Empty data is a work around for axios to prevent removing the content type header
          }
        )
        .then((response) => {
          console.log(response);
          if (response.data === "Access granted"){
            this.setState({userValidated: true})
          }
          else {
            this.setState(prevState => ({
              validations: {
                ...prevState.validations,
                masterPassword: response.data
              }
        }))
        }
    })
  }

    activateClickHandler = () => {    
      const {  rowKey} = this.props;
      const { projectName} = this.state;
      const accessToken = window.sessionStorage.getItem('token')
        const dataParams = {
        rowKey: rowKey,
        projectName : projectName.value
        
      }

      axios
        .post(
          'https://uploadrequestforquote.azurewebsites.net/api/OnActivateProject?code=X42C78zSmzvl7gYZs0evu5kydLA8Qx/v3Sm4Dlcmay8zYR/ttVicdg==&clientId=apim-APIMGTSGL',
          //'http://localhost:7071/api/OnActivateProject',
          JSON.stringify(dataParams),
          {
            headers: {
              'Content-Type': 'application/json',
            },
            timeout: 30000,
            data: {}, //Note: Empty data is a work around for axios to prevent removing the content type header
          }
        )
        .then((response) => {
          console.log(response);
          if (response.status === 200){
            window.location = '/AffiliatesPortal';
            //window.location = '/AffiliatesPortalTest';
            sessionStorage.setItem("token", accessToken);
          }
        })
  }

  render () {

    const { masterPassword, projectName, userValidated, validations } = this.state;
    const {onClose} = this.props;

    return (
      <div>
            {!userValidated && (
            <ClickAwayListener onClickAway={() => onClose()}>
                 <div style={{textAlign: 'center'}}>  
                 <TextField
                        type="password"
                        required
                        id='outlined-required'
                        label='Master Password'
                        variant='outlined'
                        error={validations.masterPassword !== ''}
                        helperText={validations.masterPassword}
                        value={masterPassword.value}
                        onChange={(e) =>
                          this.setState({
                            masterPassword: { value: e.target.value },
                          })
                       }
                      /> 
                      <div style={{marginTop: 30}}>
                        <SGLButton text='Submit'  
                        onClick={() => this.submitClickHandler()}
                        />
                        </div>
                      </div>
                 </ClickAwayListener>
            )}

                {userValidated && (
                  <ClickAwayListener onClickAway={() => onClose()}>
                  <div style={{textAlign: 'center'}}>  
                  <TextField
                         required
                         id='outlined-required'
                         label='Project Name'
                         variant='outlined'
                         // error={validations.projectName !== ''}
                         // helperText={validations.projectName}
                         value={projectName.value}
                         onChange={(e) =>
                           this.setState({
                             projectName: { value: e.target.value },
                           })
                        }
                       /> 
                       <div style={{marginTop: 30}}>
                         <SGLButton text='Activate'  
                         onClick={() => this.activateClickHandler()}
                         />
                         </div>
                       </div>
                  </ClickAwayListener>
                  )}
                  </div>
      );
  } 
};

export default ActivateModalContent;
