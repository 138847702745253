/* eslint-disable import/prefer-default-export */
import { userInterfaceActions } from '../actions/userInterfaceActions';

const initialState = {
  genericSnackbar: { open: false, message: '', success: false },
};

export const userInterfaceReducer = (state = initialState, action) => {
  switch (action.type) {
   
    case userInterfaceActions.TOGGLE_GENERIC_SNACKBAR: {
      return { ...state, genericSnackbar: action.payload };
    }
    default: {
      return state;
    }
  }
};
