import React, {useEffect} from 'react';
import PageContent from '../../components/pageContent/pageContent';
import businessValueStyles from '../common/common.module.css';
import BannerStyles from './businessValue.module.css';
import {getAppInsights} from '../../components/common/AppInsights';
import { withStyles, withTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

const styles = theme => ({
  bannerText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 'x-large',
      textAlign: 'center',
      top:210
    },
    // [theme.breakpoints.between('md', 'lg')]: {
    //   top: '15%',
    // }
  },
  regularTextContainer: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 'small',
      width: '90%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: '14px',
      width: '80%'
    }
  },
});

const BusinessValue = (props) => {
  useEffect(() => {
   
    let appInsights = getAppInsights();
    appInsights.trackEvent({ name: 'BusinessValueRender', time: Date.now() });
    appInsights.startTrackPage('BusinessValue');

    return () => {
      appInsights.stopTrackPage('BusinessValue');
    }
  });

  const { classes } = props;
  return (
    <div className={businessValueStyles.container}>
      <div className={BannerStyles.businessValue} />
      <div className={clsx(BannerStyles.bannerText, classes.bannerText)}>Business Value, Delivered.</div>
      <PageContent>
        <div className={businessValueStyles.contentContainer}>
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(businessValueStyles.regularTextContainer,classes.regularTextContainer)}
          >
            Software development is a set of computer science activities
            dedicated to creating, designing, deploying, and supporting
            software.
            <br />
            By definition, it looks simple, although there are lots of things to
            do and lots of things to consider.
            <br></br>Please continue reading below to learn more about things we
            need to take into consideration.
          </div>

          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(businessValueStyles.regularTextContainer,classes.regularTextContainer)}
          >
            <div className={businessValueStyles.focusTextU}>Cost</div>
            Hiring software developers for a development project is hard. It is
            so hard that software development companies often offer a referral
            fee to the employees as an incentive. Aside from hiring
            professionals with the necessary expertise, the company must also
            consider ways to retain them. Hence, the team of software developers
            stays with the company until the entire software product is
            delivered.
          </div>
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(businessValueStyles.regularTextContainer,classes.regularTextContainer)}
          >
            <div className={businessValueStyles.focusTextU}>
              Faster Time to Market
            </div>
            The most important question where the huge part of the budget for
            the project would go to is the monthly salaries of these highly
            skilled professionals.
            <ul>
              <li>
                How much will we be willing to allocate for this project, and
                for how long?
              </li>
              <li>
                Can we possibly retain a Solutions Architect, Project Manager,
                Project Leads, and Senior Software Engineers altogether?
              </li>
            </ul>
            The roles of these people in a software development team will have a
            significant impact on the company's ability to market faster.
          </div>
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(businessValueStyles.regularTextContainer,classes.regularTextContainer)}
          >
            <div className={businessValueStyles.focusTextU}>
              Machines and Servers
            </div>
            Additional cost concerns are the machines used by software
            developers which are expensive high-end machines.
            <ul>
              <li>
                What are we going to do with these machines after we scaled down
                the number of people engaged in the project?
              </li>
            </ul>
          </div>
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(businessValueStyles.regularTextContainer,classes.regularTextContainer)}
          >
            <div className={businessValueStyles.focusTextU}>
              Project Team Scalability
            </div>
            The project should be scalable to achieve a faster time to market.
            Therefore a company that wants to develop software should ask
            questions like
            <ul>
              <li>How fast can we augment the team if needed?</li>
              <li>How can we decrease the team gradually?</li>
            </ul>
            There are other concerns when managing a team of software engineers,
            such as team cohesiveness and the perfect combination of skills
            required in the project.
          </div>
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(businessValueStyles.regularTextContainer,classes.regularTextContainer)}
          >
            <div className={businessValueStyles.focusTextU}>
              Resilient, Maintainable, and Scalable Application
            </div>
            Lastly, the project delivered must be maintainable and resilient so
            that it can serve its purpose for an unlimited amount of time,
            making the entire investment worth it. Things to consider are
            <ul>
              <li>
                How hard it is to update the application to address changes in
                the business process?
              </li>
              <li>How long will it take to fix problems?</li>
              <li>
                How does the software application react to a drastic increase in
                usage; therefore, an increase in server resources needs to
                happen instantly with zero downtime.
              </li>
            </ul>
          </div>
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(businessValueStyles.regularTextContainer,classes.regularTextContainer)}
          >
            <div className={businessValueStyles.focusTextU}>
              Software Go Live manages all these things for our business
              partners
            </div>
            By allowing our team to develop their software application, they can
            free themselves from doing all the heavy lifting; they can focus on
            their business process while we focus on delivering value to their
            business.
          </div>
        </div>
      </PageContent>
    </div>
  );
};

export default withTheme(withStyles(styles)(BusinessValue));
