import React, {Component} from 'react';
import DataTable, { createTheme } from 'react-data-table-component';

import SGLModal from '../../components/common/SGLModal';
import ActivateModalContent from './activateModalContent';


createTheme('solarized', {
    text: {
      primary: '#268bd2',
      secondary: '#2aa198'
    },
    background: {
      default: '#002b36'
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF'
    },
    divider: {
      default: '#073642'
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)'
    }
  });
  
  const customStyles = {
    headCells: {
      style: {
        background: '#83b42b',
        color: 'white',
        '& div': {
          color: 'white',
          '&:hover': {
            color: '#e3791d'
          }
        },
        fontWeight: 'bold',
        fontSize: 16
      }
    },
    cells: {
      style: {
        fontSize: 16
      }
    }
  };

  class Referrals extends Component {
    state = {
      showProjectNameField: false,
      referenceID: "",
    }

     closeActivateModal = () => {
      this.setState({ showProjectNameField: false});
    };
  
      render(){
        const {showProjectNameField, rowKey} = this.state;

        const columns = [
          {       
           name: 'PROJECT',
          selector: 'project',
          sortable: true
        },
        {
          name: 'PROPOSED AMOUNT',
          selector: 'proposedAmount',
          sortable: true
        },
        {
          name: 'STATUS',
          selector: 'status',
          sortable: true
        },
        {
        name: '',
        selector: '',
        sortable: true,
        cell: row =>
                <div
                  style={{color: '#007bff', 
                    marginLeft: 0,
                    marginTop: 0}}
                    type="button"
                    onClick={() => this.setState({
                      showProjectNameField: !showProjectNameField, 
                      rowKey: row.rowKey, 
                    })}
           
                >
                  Activate
                </div>
                }
    ]
     
        const {referrals} = this.props;
          return(
            <div>
            <DataTable
            style={{ marginTop: 20,
                borderRadius: 10,
                boxShadow: '6px 6px 6px #00000029'}}
            title="MY REFERRALS"
            columns={columns}
            data={referrals}
            customStyles={customStyles}
            highlightOnHover
            keyField="id"
            striped
            pointerOnHover
            defaultSortField="date"
            pagination
          />
      <SGLModal open={showProjectNameField}
         height={180}
        marginTop={400}
         width={300}
         marginLeft={'70%'}
         borderRadius={0}
          >
            <ActivateModalContent 
            onClose={this.closeActivateModal}
            activateClickHandler={this.activateClickHandler}
            rowKey={rowKey}
            >  
            </ActivateModalContent>
            </SGLModal>
          </div>
          )   
      }
  }

  export default Referrals;