import React from 'react';
import PageContent from '../../components/pageContent/pageContent';

import BannerStyles from './requestForQuote.module.css';
import RFQStyles from './requestForQuoteComplete.module.css';
const RequestForProposalComplete = () => {
  return (
    <div className={RFQStyles.container}>
      <div className={BannerStyles.RFQ} />
      <PageContent>
        <div className={RFQStyles.contentContainer}>
          <div className={RFQStyles.regularTextContainer}>
            <span className={RFQStyles.focusText}>
              Thank you for sending your request.
            </span>
          </div>
          <br />
          <div className={RFQStyles.regularTextContainer}>
            <h4>
            We are currently reviewing your request and we'll get back to you soon.
            </h4>
          </div>
        </div>
      </PageContent>
    </div>
  );
};

export default RequestForProposalComplete;
