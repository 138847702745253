import React, {useEffect} from 'react';
import PageContent from '../components/pageContent/pageContent';
import InProgressStyles from './pageInProgress.module.css';
import {getAppInsights} from '../components/common/AppInsights';
const PageInProgress = () => {
  useEffect(() => {
    let appInsights = getAppInsights();
    appInsights.trackEvent({ name: 'InProgressRender', time: Date.now() });
  });
  return (
    <div className={InProgressStyles.container}>
      <div className={InProgressStyles.image} />
      <PageContent>
        <div className={InProgressStyles.contentContainer}>
          <div className={InProgressStyles.regularTextContainer}>
            <span className={InProgressStyles.focusText}>
              This page is still in progress.
            </span>
          </div>
          <br />
          <div className={InProgressStyles.regularTextContainer}>
            For inquiries, message us using the widget at the lower right corner
            of your screen.
          </div>
        </div>
      </PageContent>
    </div>
  );
};

export default PageInProgress;
