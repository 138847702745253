import React, {useEffect} from 'react';
import FooterStyles from './footer.module.css';
import LogoFooter from '../assets/logo_footer.svg';
import {getAppInsights} from '../components/common/AppInsights';
import { Link } from 'react-router-dom';
import { withTheme, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';


const styles = theme => ({

  container: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'auto',
      textAlign: 'center',
      marginTop: 40
    }
  },
  footerLogo: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 55,
      paddingTop: 30,
      paddingLeft: 'unset',
      marginBottom: 95,
      textAlign: 'center',
    },
    [theme.breakpoints.between('md','lg')]: {
      paddingLeft: 25,
    },
  },
  footerSlogan: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 'small',
      marginBottom: 50,
      textAlign: 'center',
      paddingRight: '15%',
      paddingLeft: '15%',
      paddingTop: '30px',
    },
  },

  quickLinksTitle: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      width: 'auto'
    },
  },
  quickLinksContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'unset'
    },
    [theme.breakpoints.between('md','lg')]: {
      paddingRight: 25,
    },
  },

  quickLinksGroup: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: 30,
      paddingLeft: 0
      
    },
  },
});
const OpenYouTubeLink = e => {
  e.preventDefault();
  let appInsights = getAppInsights();
  appInsights.trackEvent({ name: 'YoutubeLinkClicked', time: Date.now() });
  window.open('https://www.youtube.com/channel/UCwbMpeAmbOd3phVKBtKeH3g?fbclid=IwAR3xLDRC0bMF-69ePxDoD_ModZc7FytyDx1ujEfDZHtOBiO7rlhGWDzZ40k', '_blank');
}

const OpenTwitterLink = e => {
  e.preventDefault();
  let appInsights = getAppInsights();
  appInsights.trackEvent({ name: 'TwitterLinkClicked', time: Date.now() });
  window.open('https://twitter.com/softwaregolive', '_blank');
}

const OpenFaceBookLink = e => {
  e.preventDefault();
  let appInsights = getAppInsights();
  appInsights.trackEvent({ name: 'FacebookLinkClicked', time: Date.now() });
  window.open('https://www.facebook.com/softwaregolive', '_blank');
}

const Footer = (props) => {

  const { classes } = props;
  return (
    <>
      <div className={clsx(FooterStyles.container, classes.container)}>
        <div className={clsx(FooterStyles.footerLogo, classes.footerLogo)}>
          <img src={LogoFooter} />
          <div className={clsx(FooterStyles.footerSlogan, classes.footerSlogan)}>
            We are not just a company; we are a home where family members and
            business partners grow and succeed.
          </div>
        </div>
        <div className={clsx(FooterStyles.quickLinksContainer, classes.quickLinksContainer)}>
          <div className={clsx(FooterStyles.quickLinksGroup, classes.quickLinksGroup)}>
            <div className={clsx(FooterStyles.quickLinksTitle, classes.quickLinksTitle)}>QUICK LINKS</div>
            <div className={FooterStyles.quickLinks}>
              <Link exact to='/'>
                Home
              </Link>
            </div>
            <div className={FooterStyles.quickLinks}>
              <Link exact to='AboutUs'>
                Our Company
              </Link>
            </div>
            {/* <div className={FooterStyles.quickLinks}>Blogs</div> */}
            <div className={FooterStyles.quickLinks}>
              {' '}
              <Link exact to='RFQ'>
                Request a quote
              </Link>
            </div>
            <div className={FooterStyles.quickLinks}>
              <Link exact to='ContactUs'>
                Contact Us
              </Link>
            </div>
            <div className={FooterStyles.quickLinks}>
              <Link exact to='AffiliatesPortal'>
               Affiliate's Portal
              </Link>
            </div>
          </div>
        </div>
        <div className={clsx(FooterStyles.quickLinksContainer, classes.quickLinksContainer)}>
          <div className={clsx(FooterStyles.quickLinksGroup, classes.quickLinksGroup)}>
            <div className={clsx(FooterStyles.quickLinksTitle, classes.quickLinksTitle)}>SOCIAL</div>
            <div className={FooterStyles.quickLinks}>
            <a onClick={e => OpenYouTubeLink(e)}>
            Youtube
              </a>
            </div>
            <div className={FooterStyles.quickLinks}>
              <a onClick={e => OpenTwitterLink(e)}>
                Twitter
              </a>
            </div>
            <div style={{marginBottom: 15}} className={FooterStyles.quickLinks}>
              <a onClick={e => OpenFaceBookLink(e)}>
                Facebook
              </a>
            </div>
           
            <div style={{ background: "#a4a6a833", height: 26, width: 118,  borderRadius: 3, margin: 'auto'}}>
            <div  style={{marginTop: 3, marginLeft: 2}}class="fb-like" data-href="https://www.facebook.com/softwaregolive" data-width="" data-layout="button" data-action="like" data-size="small" data-share="true"></div>
            </div>
          </div>
        </div>
        <div className={clsx(FooterStyles.quickLinksContainer, classes.quickLinksContainer)}>
          <div className={clsx(FooterStyles.quickLinksGroup, classes.quickLinksGroup)}>
            <div className={clsx(FooterStyles.quickLinksTitle, classes.quickLinksTitle)}>LOCATION</div>
            <div className={FooterStyles.quickLinks}>
              PGMH
              Quirino Ave. Extension <br />
              Paco, Manila
              <br /> Philippines 1017
            </div>
          </div>
        </div>
      </div>
      <div className={FooterStyles.allRightsReserve}>
        <div className={FooterStyles.copyRightYear}>
          &copy; {new Date().getFullYear()}
        </div>
        <div className={FooterStyles.copyRightCompany}>SoftwareGoLive</div>
        <div className={FooterStyles.copyRightYear}>All Rights Reserved</div>
      </div>
    </>
  );
};

export default withTheme(withStyles(styles)(Footer));
