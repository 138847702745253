import React from 'react';
import Button from '../common/Button';
import { Link } from 'react-router-dom';
import OurServicesWebDevStyles from './ourservices_webdev.module.css';
import UIUXImage from '../../assets/ux.jpg';
import { withTheme, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const styles = theme => ({
  leftGreenBox: {
    [theme.breakpoints.down('sm')]: {
      height: 30,
      width: 80
    },
  },
  leftBox: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },

  centerImage: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      height: '80%',
      width: '90%'
    },
  },
  rightDescriptionHeader: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 'large',
      paddingLeft: 30,
      width: '90%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: 'large',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 'xx-large',
    },
  },
  rightBox: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingLeft: '5%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      paddingLeft: 'unset'
    },
  },
  rightDescriptionText: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      paddingLeft: 30,
      fontSize: 'small'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: 'small'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 'medium'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 'large'
    }
  },

  webDevButton: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 30,

    },
  },
  rightDescriptionContainer: {
    [theme.breakpoints.between('md', 'lg')]: {
     paddingTop: 'unset'
    },
  },
});

const WebDev = (props) => {
  const { classes } = props;
  return (
    // <div className={OurServicesWebDevStyles.container}>
    <div className={OurServicesWebDevStyles.container}>
      <div className={clsx(OurServicesWebDevStyles.leftBox, classes.leftBox)}>
        <div
          data-aos='fade-up-right'
          className={clsx(OurServicesWebDevStyles.leftGreenBox, classes.leftGreenBox)}
        ></div>
        <img
          data-aos='zoom-in'
          alt='User Interface and User Experience'
          src={UIUXImage}
          className={clsx(OurServicesWebDevStyles.centerImage, classes.centerImage)}
        />
      </div>
      <div className={clsx(OurServicesWebDevStyles.rightBox, classes.rightBox)}>
        <div className={clsx(OurServicesWebDevStyles.rightDescriptionContainer, classes.rightDescriptionContainer)}>
          <div
            data-aos='fade-left'
            className={clsx(OurServicesWebDevStyles.rightDescriptionHeader, classes.rightDescriptionHeader)}
          >
            UX & UI
          </div>
          <div data-aos='fade-up'>
            <div className={clsx(OurServicesWebDevStyles.rightDescriptionText, classes.rightDescriptionText)}>
              Let's bring your ideas to life.
              <br />
              <br />A website is the reflection of the company as a whole, so we
              carefully analyze the business of our partners and apply concepts
              as a result of extensive research to bring out a whole new
              perspective about the company and the services it has to offer.
            </div>
            <div className={clsx(OurServicesWebDevStyles.webDevButton, classes.webDevButton)}>
              <Link style={{ textDecoration: 'none' }} to='/UIUXDesign'>
                <Button text={'Learn more'} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default withTheme(withStyles(styles)(WebDev));
