import React from 'react';
import OurServicesStyles from './ourservices.module.css';
import WebDev from './ourservices_webdev';
import WebServices from './ourservices_webservices';
import WindowsServices from './ourservices_ux';
import clsx from 'clsx';
import { withTheme, withStyles } from '@material-ui/core/styles';


const styles = theme => ({
  weHelpText: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: 'large',
      paddingTop: '15%',
      paddingLeft: 0
    },
    [theme.breakpoints.between('sm','md')]: {
      paddingTop: '7%',
    },
    [theme.breakpoints.between('md','lg')]: {
      paddingTop: '5%',
    },
  },
  weHelpTextSub: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      fontSize: 'small',
      paddingLeft: 0
    },
  },
  ourServicesContainer: {
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'unset',
      width: '93%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      alignSelf: 'unset',
      width: '100%'
    },
  },
});

const OurServices = (props) => {
  const { classes } = props;
  return (
    <>
      <div className={OurServicesStyles.bannerImage}>
        <div className={clsx(OurServicesStyles.weHelpText, classes.weHelpText)}>
          We help businesses grow
        </div>
        <div className={clsx(OurServicesStyles.weHelpTextSub, classes.weHelpTextSub)}>
          fueled by our passion and dedication.
        </div>
      </div>

      <div className={clsx(OurServicesStyles.ourServicesContainer, classes.ourServicesContainer)}>
        <WebDev />
        <WebServices />
        <WindowsServices />
      </div>
    </>
  );
};


export default withTheme(withStyles(styles)(OurServices));