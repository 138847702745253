import { combineReducers } from 'redux';
import { userInterfaceReducer } from './userInterfaceReducer';
import { affiliateInfoReducer } from './affiliateInfoReducer';


const rootReducer = combineReducers({
  userInterface: userInterfaceReducer,
  affiliateInfo: affiliateInfoReducer,
});

export default rootReducer;