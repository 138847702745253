import React from 'react';
import { Modal } from '@material-ui/core';
import ModalStyles from './SGLModal.module.css';

const SGLModal = ({
  children,
  open,
  title,
  width,
  height,
  marginTop,
  marginLeft,
  borderRadius
}) => {
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
    >
      <div
        className={ModalStyles.modal}
        style={{ width, height, marginTop, marginLeft, borderRadius }}
      >
        <div className={ModalStyles.title}>{title}</div>
        <div>{children}</div>
      </div>
    </Modal>
  );
};

export default SGLModal;
