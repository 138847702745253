import React from 'react';
import { connect } from 'react-redux';

const TermsAndConditionsText = (props) => {
const {affiliateFirstName,  affiliateLastName, affiliateAddress} = props;
const dateToday = new Date().toLocaleDateString();

  return (
    <div>
        This Referral Agreement (the “Agreement”) is entered into {dateToday} (the “Effective Date”), by and between <strong>Software Go Live</strong>, with an address of PGMH Paco Manila, Philippines (the “Company”) and {affiliateFirstName} {affiliateLastName}, with an address of {affiliateAddress}, (the “Affiliate”), collectively “the Parties.”
        <br/><br/>
        <strong>Purpose.</strong> Software Go Live is in Web Application Development, Systems Integration, User Interface, and User Experience Design. Software Go Live is desirous of gaining additional clients/customers for Web and Mobile application Designing and Development, Systems Integration. 
        <br/><br/>
        <strong>Referral Arrangement.</strong> Upon accepting this Agreement, the Affiliate may, from time to time, refer potential clients/customers to Software Go Live. 
        <br/><br/>
        The Company reserves the right to restrict the Affiliate's rights through various restrictions, including geographic limits.  
        <br/><br/>
        The Affiliate agrees to introduce the services under the Software Go Live brand. The services shall not be re-branded for any reason. 
        <br/><br/>
        <strong>Compensation.</strong> The Company shall award the Affiliate 10% of the project's contract price for each successful referral. A successful referral is defined as a referral that becomes a client/customer of Software Go Live. Company shall pay the Affiliate the 10% referral fee on a per invoice basis relative to the billed amount.
        <br/><br/>
        <strong>Term. </strong>This Agreement shall commence upon the Effective Date, as stated
        above, and will continue until mutual parties agreed termination date and term. 
        <br/><br/>  
        <strong>Termination.</strong> This Agreement may be terminated at any time by either Party
        upon 30 days written notice to the other party.
        <br/><br/>  
        <strong>Representations and Warranties. </strong>Both Parties represent that they are fully authorized to enter into this Agreement. The performance and obligations of either Party will not violate or infringe upon the rights of any third-party or violate any other agreement between the Parties, individually, and any other person, organization, or business or any law or governmental regulation.
        <br/><br/>  
        <strong>Indemnity.</strong> The Parties each agree to indemnify and hold harmless the other Party, its respective affiliates, officers, agents, employees, and permitted successors and assigns against any claims, losses, damages, liabilities, penalties, punitive damages, expenses, reasonable legal fees, and costs of any kind or amount whatsoever, which result from the negligence of or breach of this Agreement by the indemnifying Party, its respective successors and assigns that occurs in connection with this Agreement. This section remains in full force and effect even after termination of the Agreement by its natural termination or the early termination by either Party.
        <br/><br/> 
        <strong>Limitation of Liability.</strong>  Under no circumstances shall either Party be liable to the other Party or any third party for any damages resulting from any part of this Agreement such as, but not
        limited to, loss of revenue or anticipated profit or lost business, costs of delay or failure of delivery, which are not related to or the direct result of a party's negligence or breach.
        <br/><br/> 
        <strong>Disclaimer of Warranties.</strong> Affiliate shall refer potential clients/customers as requested by Software Go Live. The Affiliate does not represent or warrant that such referrals will create any additional profits, sales, exposure, brand recognition, or the like. Affiliate has no responsibility to Software Go Live if the referrals do not lead to company's desired result(s).
        <br/><br/> 
        <strong>Waiver.</strong> The failure by either party to exercise any right, power or privilege under the terms of this Agreement will not be construed as a waiver of any subsequent or further exercise of that right, power or privilege or the exercise of any other right, power or privilege.
        <br/><br/>  
        <strong>Legal Fees.</strong> In the event of a dispute resulting in legal action, the successful party will be entitled to its legal fees, including but not limited to its attorneys' fees.             
        <br/><br/>      
        <strong>Legal and Binding Agreement.</strong> This Agreement is legal and binding between the Parties as stated above. This Agreement may be entered into and is legal and binding in the Philippines. The Parties each represent that they have the authority to enter into this Agreement.         
        <br/><br/>  
        <strong>Governing Law and Jurisdiction.</strong> The Parties agree that this Agreement shall be governed by the Philippines in which both Parties do business. In the event that the Parties do business in different States and/or Countries, this Agreement shall be governed by Philippines law.
        <br/><br/>    
        <strong>Entire Agreement.</strong> The Parties acknowledge and agree that this Agreement represents the entire Agreement between the Parties. If the Parties desire to change, add, or otherwise modify any terms, they shall do so in writing to be signed by both parties.              
        <br/><br/>   
        The Affiliate agrees to the terms and conditions set forth above as demonstrated by accepting the terms and conditions on the registration page.         
    </div>
  );
};

const mapStateToProps = state => {
    return {
      affiliateFirstName: state.affiliateInfo.affiliateFirstName,
      affiliateLastName: state.affiliateInfo.affiliateLastName,
      affiliateAddress: state.affiliateInfo.affiliateAddress,
    };
  };

export default connect(
    mapStateToProps,
    null)(TermsAndConditionsText);
