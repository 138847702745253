import React, {useEffect} from 'react';
import PageContent from '../../components/pageContent/pageContent';
import BannerStyles from './ourCompany.module.css';
import OurCompanyStyles from '../common/common.module.css';
import {getAppInsights} from '../../components/common/AppInsights';

import { withTheme, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const styles = theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '90%'
    },
  },
  bannerText: {
    [theme.breakpoints.between('xs','md')]: {
      fontSize: 'x-large',
      top: 200,
      textAlign: 'center'
    },
  },
  regularTextContainer: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 'small',
      width: '90%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: '14px',
      width: '80%'
    }
  },
  customizedTextContainer:{
    [theme.breakpoints.down('sm')]: {
      fontSize: 'small',
      width: '90%',
      textAlign: 'left'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: '14px',
      width: '80%',
      textAlign: 'left'
    }
  },
  focusText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 'large',
    },
  },
});


const OurCompany = (props) => {
 
useEffect(() => {
  let appInsights = getAppInsights();
  appInsights.trackEvent({ name: 'OurCompanyRender', time: Date.now() });
  //appInsights.trackPageView({name: 'OurCompany'}); this is alternative to tracking page view but the problem here is that it doesn't track individual components in SPA instead it totals the page load for the SPA giving them equal page load seconds tracking regardless of the component loaded.
  appInsights.startTrackPage('OurCompany');

  return () => {
    appInsights.stopTrackPage('OurCompany');
  }
});

  const { classes } = props;
  return (
    <div className={clsx(OurCompanyStyles.container)}>
      <div className={BannerStyles.ourCompanyImage} />
      <div className={clsx(BannerStyles.bannerText, classes.bannerText)}>Our Purpose of Existence</div>
      <PageContent>
        <div className={OurCompanyStyles.contentContainer}>
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(OurCompanyStyles.regularTextContainer, classes.regularTextContainer)}
          >
            In <strong>Software Go Live</strong>, we firmly believe that
            constant learning is far more critical than educational attainment.
            The demands of ever-changing technologies tell us that the best
            tools and practices today could be outdated tomorrow. Therefore, we
            maintain a culture built on the foundation of
            <br />
          </div>
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(OurCompanyStyles.regularTextContainer, classes.customizedTextContainer)}
          >
            <span className={clsx(OurCompanyStyles.focusText, classes.focusText)}>Our core values</span>
            Passion, Innovation, Commitment, Integrity, and Respect. As a
            result, adapting to changing technologies is always fun and not
            burdensome. Hence we make a significant impact on our business
            partners, the lives of our members, and the community in general.
          </div>
          <br />
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(OurCompanyStyles.regularTextContainer, classes.regularTextContainer)}
          >
            <span className={clsx(OurCompanyStyles.focusText, classes.focusText)}>Our Vision</span>
            is to create a world where educational attainment is not a primary
            requirement to deliver high-quality products and services
            successfully.
          </div>
          <br />
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(OurCompanyStyles.regularTextContainer, classes.regularTextContainer)}
          >
            Because we live in a world of endless technology evolution, Software
            Go Live accepts the constant challenge to evolve as well. Because of
            this, we invest our time and effort in carefully choosing members
            with an inherent will to succeed.
          </div>
          <br />
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(OurCompanyStyles.regularTextContainer, classes.regularTextContainer)}
          >
            <span className={clsx(OurCompanyStyles.focusText, classes.focusText)}>Our Mission</span>
            is to build and deliver software applications fueled by passion and
            commitment to excellence.
          </div>
          <br />
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(OurCompanyStyles.regularTextContainer, classes.regularTextContainer)}
          >
            We preserve an environment where respect for individuals is an
            everyday requirement so that our members work with care for each
            other as part of one team driving towards a common goal.
          </div>
          <br />
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(OurCompanyStyles.regularTextContainer, classes.regularTextContainer)}
          >
            Software Go Live is not just an ordinary technology consulting
            company.
            <br />
            <span className={clsx(OurCompanyStyles.focusText, classes.focusText)}>We are family</span>
            consist of members who are experts in the software development
            industry and of members with diverse backgrounds.
          </div>
          <br />
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(OurCompanyStyles.regularTextContainer, classes.regularTextContainer)}
          >
            We believe that by developing inexperienced members, we provide
            equal opportunities to the less fortunate, and we are nurturing
            future experts. We welcome those people who work day and night to
            provide food on the table for their families, people who work and
            study at the same time, and to the people who exert great efforts to
            improve the lives of other people they value most.
          </div>
          <br />
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(OurCompanyStyles.regularTextContainer, classes.customizedTextContainer)}
          >
            We know this is a huge dream, but
            <span className={clsx(OurCompanyStyles.focusText, classes.focusText)}> This is Us;</span>
            everyone has lots of stories to tell, and it fuels our burning
            desire to achieve excellence.
          </div>
          <br />
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(OurCompanyStyles.regularTextContainer, classes.regularTextContainer)}
          >
            We provide a home where intellectual capabilities are honed and not
            a product of a rigorous recruitment process.
          </div>
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(OurCompanyStyles.regularTextContainer, classes.regularTextContainer)}
          >
            <span className={clsx(OurCompanyStyles.focusText, classes.focusText)}>
              Our measure of success
            </span>
            is the success of our business partners. For that reason, we
            continually seek ways for our members to equip with knowledge of
            bleeding-edge technologies and best practices.
            <br /> <br />
            We believe that this is the best we can do to make valuable
            contributions to the success of our business partners.
          </div>
        </div>
      </PageContent>
    </div>
  );
};

export default withTheme(withStyles(styles)(OurCompany));