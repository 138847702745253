import React, { createRef, useState, useCallback } from 'react';
import PageContent from '../../components/pageContent/pageContent';

import axios from 'axios';
//import Button from '../../components/common/Button';
import BannerStyles from './requestForQuote.module.css';
import RFQStyles from './requestForQuoteComplete.module.css';
const RequestForQuoteComplete = () => {
  return (
    <div className={RFQStyles.container}>
      <div className={BannerStyles.RFQ} />
      <PageContent>
        <div className={RFQStyles.contentContainer}>
          <div className={RFQStyles.regularTextContainer}>
            <span className={RFQStyles.focusText}>
              Thank you for sending your request.
            </span>
          </div>
          <br />
          <div className={RFQStyles.regularTextContainer}>
            <h4>
              We have sent a request reference number to the email you have
              provided.
            </h4>
          </div>
        </div>
      </PageContent>
    </div>
  );
};

export default RequestForQuoteComplete;
