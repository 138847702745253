import React from 'react';
import Carousel, { PagingDots } from 'nuka-carousel';
import SGLButton from '../common/Button';
import carouselStyles from './carousel.module.css';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { withTheme, withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  businessValueText: {
    position: 'absolute',
    top: 330,
    left: 139,
    width: 441,
    fontFamily: 'Lato',
    fontWeight: 900,
    fontSize: 'xx-large',
    letterSpacing: 0,
    color: '#484b50',
    opacity: 1,
    [theme.breakpoints.down('sm')]: {
      left: 'unset',
      fontSize: 'large',
      top: 265,
      width: '100%',
      textAlign: 'center',
    },
    [theme.breakpoints.between('sm','md')]: {
      top: 125,
      textAlign: 'unset',
      left: 139
    },
    [theme.breakpoints.between('md','lg')]: {
      top: 260,
      textAlign: 'unset',
      left: 139
    },
  },
  bussinessValueDescription: {
    [theme.breakpoints.down('sm')]: {
      left: 'unset',
      width: '60%',
      textAlign: 'center',
      fontSize: 'small',
      top: 310,
      marginLeft: '20%',
      marginRight: '20%'
    },
    [theme.breakpoints.between('sm','md')]: {
      top: 160,
      textAlign: 'unset',
      left: 139,
      marginLeft: 'unset',
      marginRight: 'unset',
    },
    [theme.breakpoints.between('md','lg')]: {
      top: 320,
      textAlign: 'unset',
      left: 139,
      marginLeft: 'unset',
      marginRight: 'unset',
    },
  },
  businessValueLearnMoreButton: {
    [theme.breakpoints.down('sm')]: {
      left: 'unset',
      top: 380,
      width: '100%',
      textAlign: 'center'
    },
    [theme.breakpoints.between('sm','md')]: {
      top: 205,
      textAlign: 'unset',
      left: 139
    },
    [theme.breakpoints.between('md','lg')]: {
      top: 390,
      textAlign: 'unset',
      left: 139
    },
  },
  coreCompetencyText: {
    [theme.breakpoints.down('sm')]: {
      left: 'unset',
      fontSize: 'large',
      top: '67%',
      width: '100%',
      textAlign: 'center'
    },
    [theme.breakpoints.between('sm','md')]: {
      top: 45,
      left: '60%',
      textAlign: 'unset'
    },
    [theme.breakpoints.between('md','lg')]: {
      top: 155,
      left: '60%',
      textAlign: 'unset'
    },
  },
  competencyDescription: {
    [theme.breakpoints.down('sm')]: {
      top: '74%',
      left: '20%',
      width: '60%',
      textAlign: 'center',
      fontSize: 'small',
    },
    [theme.breakpoints.between('sm','md')]: {
      top: 80,
      left: '60%',
      textAlign: 'unset',
      width: '39%'
    },
    [theme.breakpoints.between('md','lg')]: {
      top: 215,
      left: '60%',
      textAlign: 'unset'
    },
  },
  competencyLearnMoreButton: {
    [theme.breakpoints.down('sm')]: {
      left: 'unset',
      top: '84%',
      width: '100%',
      textAlign: 'center'
    },
    [theme.breakpoints.between('sm','md')]: {
      top: 135,
      left: '60%',
      textAlign: 'unset'
    },
    [theme.breakpoints.between('md','lg')]: {
      top: 265,
      left: '60%',
      textAlign: 'unset'
    },
    // [theme.breakpoints.between('sm','md')]: {
    //   left: '42%',
    //   top: 650
    // },
  },
  requestQuoteText: {
    [theme.breakpoints.down('sm')]: {
      left: 'unset',
      fontSize: 'large',
      top: 150,
      width: '100%',
      textAlign: 'center',
    },
    [theme.breakpoints.between('sm','md')]: {
      top: 30,
      left: '55%',
      width: 441,
      textAlign: 'unset',
    },
    [theme.breakpoints.between('md','lg')]: {
      top: 75,
      left: '60%',
      textAlign: 'unset'
    },
  },
  requestQuoteDescription: {
    [theme.breakpoints.down('sm')]: {
      left: '20%',
      width: '60%',
      textAlign: 'center',
      fontSize: 'small',
      top: 200
    },
    [theme.breakpoints.between('sm','md')]: {
      top: 65,
      left: '55%',
      width: '35%',
      textAlign: 'unset',
    },
    [theme.breakpoints.between('md','lg')]: {
      top: 135,
      left: '60%',
      width: '35%',
      textAlign: 'unset',
    },
  },
  requestQuoteButton: {
    [theme.breakpoints.down('sm')]: {
      left: 'unset',
      top: 270,
      width: '100%',
      textAlign: 'center'
    },
    [theme.breakpoints.between('sm','md')]: {
      top: 115,
      left: '55%',
      textAlign: 'unset',
    },
    [theme.breakpoints.between('md','lg')]: {
      top: 190,
      left: '60%',
      textAlign: 'unset',
    },
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      height: '80vh'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: 300
    },
    [theme.breakpoints.between('md','lg')]: {
      height: 500
    },
    [theme.breakpoints.between('lg','xl')]: {
      height: '85vh'
    },
    
  },
  carouselItem: {
    [theme.breakpoints.down('sm')]: {
      height: '80vh'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: '300px'
    },
    [theme.breakpoints.between('md','lg')]: {
      height: 500
    },
    [theme.breakpoints.between('lg','xl')]: {
      height: '85vh'
    },

  },
  darkBackground: {
    [theme.breakpoints.down('sm')]: {
    height: "inherit",
    background: "#0000008c"
    },
    [theme.breakpoints.up('sm')]: {
      height: "inherit",
      background: "none",
      },
  }
});

const carousel = (props) => {
  const { classes } = props;
  return (
    <div className={classes.container}>
      <Carousel
        autoplay={true}
        wrapAround={true}
        autoplayInterval={2500}
        pauseOnHover={false}
        renderBottomCenterControls={(defaultProps) => {
          return (
            <div>
              <PagingDots slideCount={2} {...defaultProps} />
            </div>
          );
        }}
      >
        <div
          className={clsx(
            'firstItem',
            carouselStyles.carouselItem,
            carouselStyles.firstCarouselItem,
            classes.carouselItem
          )}
        >
          <span className={classes.businessValueText}
          >
            Business Value, Delivered.
          </span>
          <span className={clsx(carouselStyles.bussinessValueDescription, classes.bussinessValueDescription)}>
            We value our work and we are dedicated in helping you grow your
            business.
          </span>
          <Link exact to='/BusinessValue'>
            <div className={clsx(carouselStyles.businessValueLearnMoreButton, classes.businessValueLearnMoreButton)}>
              <SGLButton text='Learn more' />
            </div>
          </Link>
        </div>
        <div
          className={clsx(
            'secondItem',
            carouselStyles.carouselItem,
            carouselStyles.secondCarouselItem,
            classes.carouselItem
          )}
          // onClick={(e) => props.onCoreCompetencyClick(e)}
        >
          <span className={clsx(carouselStyles.coreCompetencyText, classes.coreCompetencyText)}>
            Our core competencies
          </span>
          <span className={clsx(carouselStyles.competencyDescription, classes.competencyDescription)}>
            Check out how we can contribute to your business.
          </span>
          <div className={clsx(carouselStyles.competencyLearnMoreButton, classes.competencyLearnMoreButton)}>
            {/* <Link style={{ textDecoration: 'none' }} to='/InProgress'> */}
            <a  style={{textDecoration: "none"}} href="#section2">
              <SGLButton text='Learn more' />
            {/* </Link> */}
            </a>
          </div>
        </div>
        <div
          className={clsx(
            'secondItem',
            carouselStyles.carouselItem,
            carouselStyles.thirdCarouselItem,
            classes.carouselItem
          )}
        >
          <div 
          className={classes.darkBackground}
          >
          <span className={clsx(carouselStyles.requestQuoteText, classes.requestQuoteText)}>
            Request a quote
          </span>
          <span className={clsx(carouselStyles.requestQuoteDescription, classes.requestQuoteDescription)}>
            Let us build your dream application the way you want it.
          </span>
          <div className={clsx(carouselStyles.requestQuoteButton, classes.requestQuoteButton)}>
            <Link style={{ textDecoration: 'none' }} to='/RFQ'>
              <SGLButton text='Request now' />
            </Link>
          </div>
        </div>
        </div>
      </Carousel>
    </div >
  );
};

export default withTheme(withStyles(styles)(carousel));
