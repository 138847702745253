import React, { useState, useEffect } from 'react';
import {getAppInsights} from '../../components/common/AppInsights';
import PageContent from '../../components/pageContent/pageContent';
import { withTheme, withStyles } from '@material-ui/core/styles';
import SGLButton from '../../components/common/Button';

import BannerStyles from './registration.module.css';
import RegistrationStyles from './registration.module.css';
import {withRouter} from "react-router-dom";

const styles = theme => ({
  codeContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'unset'
    },
  },
  codeText: {
    marginTop: 40, 
    marginRight: 20,
  },
  code: {
    marginTop: 20, 
    marginRight: 20,
    fontSize: 32,
    [theme.breakpoints.down('sm')]: {
      fontSize: 25
    },
  },
  
 
});

const submitRequest = () => {
  window.location = "/InProgress"
}

const RegistrationComplete = props => {

  useEffect(() => {
   
    let appInsights = getAppInsights();
    appInsights.trackEvent({ name: 'RegistrationComplete', time: Date.now() });
    appInsights.trackPageView({name: 'RegistrationComplete'})
  });
  const { classes } = props;
  const firstName = window.localStorage.getItem('partner')

  return (
    <div className={RegistrationStyles.container}>
      <div className={BannerStyles.BusinessPartner} />
      <PageContent>
        <div className={RegistrationStyles.contentContainer}>
          <div className={RegistrationStyles.regularTextContainer}>
          <span className={RegistrationStyles.focusText}>
            Welcome {firstName}! <br/>
            It is our great pleasure to have you as our newest affiliate!
          </span>
          </div>
          <br />
          <div className={RegistrationStyles.regularTextContainer}>
            <h5>Our business partner's success is our utmost priority, so please note your affiliate's code.
            <br/>
            We also included it in your welcome e-mail should you need it in the future.
            <br/>
            Please check your spam folder if you don't see it your inbox. Thank you! </h5>
            <div className={classes.codeContainer}>
            <h4 className={classes.codeText}>Your Affiliate's Code is:</h4>
            <strong className={classes.code}>
            {props.match.params.code}
            </strong>
            </div>
          </div>
        </div>
       
      </PageContent>
      <div style={{display: 'inline-block', marginBottom: 30}}>
          <SGLButton
              variant='contained'
              color='primary'
              onClick={() => submitRequest()}
              text={"Continue"}
          >
          </SGLButton>
      </div>
    </div>
  );
};

export default withRouter(withTheme(withStyles(styles)(RegistrationComplete)));