import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SnackbarStyles from './Snackbar.module.css';

const ActionNotification = props => {
  const { open, message, success, onClose } = props;
  const style =
    success === true ? SnackbarStyles.success : SnackbarStyles.error;
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      key="bottom-right"
      open={open}
      autoHideDuration={5000}
      onClose={() => onClose()}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={() => onClose()}
        >
          <CloseIcon
            className={SnackbarStyles.closeIcon}
          />
        </IconButton>
      ]}
      ContentProps={{
        'aria-describedby': 'message-id',
        classes: {
          root: style
        }
      }}
      message={<span id="message-id">{message}</span>}
    />
  );
};

export default ActionNotification;
