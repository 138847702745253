import React from "react";
import PageContentStyles from "./pageContent.module.css";

const PageContent = ({ children }) => {
  return (
    <div className={PageContentStyles.container}>
      <div className={PageContentStyles.body}>{children}</div>
    </div>
  );
};

export default PageContent;
