import React, { createRef, useState, useEffect, useCallback } from 'react';
import PageContent from '../../components/pageContent/pageContent';
import { withTheme, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import RFQStyles from '../common/common.module.css';
import { ReactComponent as Loader } from '../../assets/svg-loader.svg';
import axios from 'axios';
import { connect } from 'react-redux';
import { userInterfaceActions } from '../../store/actions/userInterfaceActions';
//import Button from '../../components/common/Button';
import BannerStyles from './requestForQuote.module.css';
import { TextField, Button } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import CountrySelect from '../../components/common/CountryList';
import {getAppInsights} from '../../components/common/AppInsights';
import ReCAPTCHA from 'react-google-recaptcha';
import ActionNotification from '../../components/common/Snackbar';
const shortid = require('shortid');



const Accept = (onDrop) => {
  const {
    acceptedFiles,
    rejectedFiles,
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,
    accept:
      'application/pdf, application/msword,text/plain, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  });

  const acceptedFilesItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <section>
      <div {...getRootProps({ className: BannerStyles.dropZone })}>
        <input {...getInputProps()} />
        <strong>
          <p>
            Drag and Drop an attachment file here, or Click to select a file
          </p>
        </strong>
        <em>
          Allowable files are PDF (.pdf), Word Document (.doc / .docx) and Text
          file (.txt)
        </em>
        <aside>
          <h4>Accepted files</h4>

          <ul>{acceptedFilesItems}</ul>
        </aside>
      </div>
    </section>
  );
};
const dropzoneRef = createRef();

const styles = theme => ({
  column: {
    [theme.breakpoints.down('sm')]: {
      flex: 'unset'
    },
  },
  bannerText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 'x-large',
     top: 210
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: 'x-large',
      textAlign: 'center',
      marginTop: 'unset'
    },
    [theme.breakpoints.between('md', 'lg')]: {
    }
  },
  row: {
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    },
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      flexDirection: 'row',
      marginLeft: 10
    },
    '& button': {
      marginTop: 20,
      [theme.breakpoints.up('sm')]: {
        marginTop: 0,
      },
    }
  },
});

const RequestForQuote = (props) => {
  useEffect(() => {
   
    let appInsights = getAppInsights();
    appInsights.trackEvent({ name: 'RequestForQuoteRender', time: Date.now() });
    appInsights.startTrackPage('RequestForQuote');

    return () => {
      appInsights.stopTrackPage('OurProcess');
    }
  });
  const { classes , genericSnackbar, toggleSnackbar} = props;
  let currentState = {};
  const submitRequest = (state) => {
    let valid = true;

    if (!state.name.value) {
      valid = false;
      currentState = {
        ...currentState,
        name: { value: '', validation: 'We are glad to know more about you.' },
      };
    }

    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.email.value)
    ) {
      valid = false;
      currentState = {
        ...currentState,
        email: { value: '', validation: 'Invalid email address.' },
      };
    }

    if (!state.phone.value) {
      valid = false;
      currentState = {
        ...currentState,
        phone: { value: '', validation: 'Please input your phone number.' },
      };
    }

    if (!state.projectType.value) {
      valid = false;
      currentState = {
        ...currentState,
        projectType: {
          value: '',
          validation: 'Please let us know how we can help you.',
        },
      };
    }
    if (!state.projectTimeline.value) {
      valid = false;
      currentState = {
        ...currentState,
        projectTimeline: {
          value: '',
          validation: 'What is your estimated project completion in months?',
        },
      };
    }

    if (!state.companyName.value) {
      valid = false;
      currentState = {
        ...currentState,
        companyName: {
          value: '',
          validation: 'Please input your company name.',
        },
      };
    }

    if (!state.captcha.value) {
      valid = false;
      currentState = {
        ...currentState,
        captcha: {
          value: '',
          validation: 'Please verify that you are human.',
        },
      };
    }

    
    if (!state.referralCode.value) {
      valid = false;
      currentState = {
        ...currentState,
        referralCode: {
          value: '',
          validation: 'Please input your referral code.',
        },
      };
    }

    if (!valid) {
      setState({ ...state, ...currentState });
    }

    if (valid) {
      setState({
        ...state,
        showLoading: { value: true },
      });
      const fd = new FormData();
      if (attachment) {
        fd.append('attachment', attachment, attachment.path);
      }

      const dataParams = {
        Name: state.name.value,
        Email: state.email.value,
        Phone: state.phone.value,
        CompanyName: state.companyName.value,
        ProjectType: state.projectType.value,
        TimeLine: state.projectTimeline.value,
        EstimatedBudget: state.estimatedBudget.value,
        Message: state.message.value,
        Country: state.country.value,
        ReferenceID: shortid.generate().toUpperCase(),
        ReferralCode: state.referralCode.value
      };
      fd.set('data', JSON.stringify(dataParams));
      setTimeout(() => {
        axios
          .post(
            'https://apimgtsgl.azure-api.net/sglbe/OnRFQReceived',
            fd,
            {
              headers: {
                "Ocp-Apim-Subscription-Key": "fe765ecf6cbc46c5b21f52ceb4a86843;product=sglapi",
                "Ocp-Apim-Trace": true
              },
              timeout: 30000
            }
          )
          .then(response => {
            console.log(response);
            if (response.status === 202){
              window.location = '/RFPC';
            }
          })
          .catch(() => {
            toggleSnackbar({ open: true, message:  'This machine currently has problems communicating to the server. Please try again later.', success: false });
          })
          .finally(() => {
            setState({
              ...state,
              showLoading: { value: false },
            });
          });
      }, 1000);
    }
  };
  const [state, setState] = useState({
    name: { value: '', validation: '' },
    email: { value: '', validation: '' },
    phone: { value: '', validation: '' },
    country: { value: '', validation: '' },
    projectType: { value: '', validation: '' },
    estimatedBudget: { value: '', validation: '' },
    projectTimeline: { value: '', validation: '' },
    companyName: { value: '', validation: '' },
    message: { value: '', validation: '' },
    captcha: { value: '', validation: '' },
    showLoading: { value: false },
    referralCode: { value: '', validation: '' },
  });

  const [attachment, setAttachment] = useState();

  const onDrop = useCallback((acceptedFiles) => {
    setAttachment(acceptedFiles[0]);
  }, []);
  const onCountryChange = (value) => {
    setState({
      ...state,
      country: { value: value, validations: '' },
    });
  };

  const onCaptchaChanged = (param) => {
    setState({
      ...state,
      captcha: { value: param, validations: '' },
    });
  };

  const {
    name,
    email,
    phone,
    country,
    projectType,
    estimatedBudget,
    projectTimeline,
    companyName,
    message,
    captcha,
    showLoading,
    referralCode
  } = state;
  return (
    <>
      <form>
        <div className={RFQStyles.container}>
          <div className={BannerStyles.RFQ} />
          <div className={clsx(BannerStyles.bannerText, classes.bannerText)}>Request for Proposal</div>
          <PageContent>
            <div
              style={{
                alignContent: 'center',
                width: '80%',
                boxSizing: 'border-box',
              }}
            >
              <div className={clsx(BannerStyles.row, classes.row)}>
                <div className={clsx(BannerStyles.column, classes.column)}>
                  <TextField
                    required
                    id='outlined-required'
                    label='Your Name'
                    variant='outlined'
                    error={name.validation !== ''}
                    helperText={name.validation}
                    value={name.value}
                    onChange={(e) =>
                      setState({
                        ...state,
                        name: { value: e.target.value, validation: '' },
                      })
                    }
                  />
                </div>
                <div className={BannerStyles.column}>
                  <TextField
                    required
                    id='outlined-required'
                    label='Email Address'
                    variant='outlined'
                    value={email.value}
                    error={email.validation !== ''}
                    helperText={email.validation}
                    onChange={(e) => {
                      setState({
                        ...state,
                        email: { value: e.target.value, validation: '' },
                      });
                    }}
                  />
                </div>
              </div>
              <div className={clsx(BannerStyles.row, classes.row)}>
                <div className={clsx(BannerStyles.column, classes.column)}>

                  <TextField
                    required
                    id='outlined-required'
                    label='Phone number'
                    variant='outlined'
                    value={phone.value}
                    error={phone.validation !== ''}
                    helperText={phone.validation}
                    onChange={(e) =>
                      setState({
                        ...state,
                        phone: { value: e.target.value, validation: '' },
                      })
                    }
                  />
                </div>
                <div className={BannerStyles.column}>
                  {CountrySelect(country.value, onCountryChange)}
                </div>
              </div>
              <div className={clsx(BannerStyles.row, classes.row)}>
                <div className={clsx(BannerStyles.column, classes.column)}>
                  <TextField
                    required
                    id='outlined-required'
                    label='Project Type'
                    variant='outlined'
                    value={projectType.value}
                    error={projectType.validation !== ''}
                    helperText={projectType.validation}
                    onChange={(e) =>
                      setState({
                        ...state,
                        projectType: { value: e.target.value, validation: '' },
                      })
                    }
                  />
                </div>
                <div className={clsx(BannerStyles.column, classes.column)}>
                  <TextField
                    id='outlined-required'
                    label='Estimated budget to complete the project'
                    variant='outlined'
                    value={estimatedBudget.value}
                    onChange={(e) =>
                      setState({
                        ...state,
                        estimatedBudget: {
                          value: e.target.value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div className={clsx(BannerStyles.row, classes.row)}>
                <div className={clsx(BannerStyles.column, classes.column)}>
                  <TextField
                    required
                    id='outlined-required'
                    label='Project Timeline'
                    variant='outlined'
                    value={projectTimeline.value}
                    error={projectTimeline.validation !== ''}
                    helperText={projectTimeline.validation}
                    onChange={(e) =>
                      setState({
                        ...state,
                        projectTimeline: {
                          value: e.target.value,
                          validation: '',
                        },
                      })
                    }
                  />
                </div>
                <div className={BannerStyles.column}>
                  <TextField
                    required
                    id='outlined-required'
                    label='Company Name'
                    variant='outlined'
                    value={companyName.value}
                    error={companyName.validation !== ''}
                    helperText={companyName.validation}
                    onChange={(e) =>
                      setState({
                        ...state,
                        companyName: { value: e.target.value, validation: '' },
                      })
                    }
                  />
                </div>
              </div>
              <div className={BannerStyles.row}>
                <div className={BannerStyles.column}>{Accept(onDrop)}</div>
              </div>
              <div className={BannerStyles.row}>
                <div className={BannerStyles.column}>
                  <TextField
                    id='outlined-required'
                    label='Message'
                    variant='outlined'
                    multiline
                    value={message.value}
                    onChange={(e) =>
                      setState({
                        ...state,
                        message: { value: e.target.value },
                      })
                    }
                  />
                </div>
              </div>
              <div className={BannerStyles.row}>
                <div className={BannerStyles.column}>
                  <TextField
                    id='outlined-required'
                    label='Referral Code'
                    variant='outlined'
                    multiline
                    value={referralCode.value}
                    onChange={(e) =>
                      setState({
                        ...state,
                        referralCode: { value: e.target.value },
                      })
                    }
                  />
                </div>
                <div className={BannerStyles.column}></div>
              </div>
              <div className={clsx(BannerStyles.submitButton, classes.submitButton)}>
                <ReCAPTCHA
               
                  sitekey='6LeWiKcZAAAAALqgxdyrlo1uTRGHK1aTiGDoRmpF'
                  onChange={onCaptchaChanged}
                />
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => submitRequest(state)}
                >
                  Submit
                </Button>
              </div>
              {captcha.validation && (
                <div
                  className={BannerStyles.submitButton}
                  style={{ color: 'red' }}
                >
                  {captcha.validation}
                </div>
              )}
            </div>
          </PageContent>
        </div>
      </form>
      {genericSnackbar.open && (
          <ActionNotification
            open={genericSnackbar.open}
            message={genericSnackbar.message}
            success={genericSnackbar.success}
            onClose={toggleSnackbar}
          />
        )}
      {showLoading.value && (
        <div className={RFQStyles.loader}>
          <div
            style={{
              height: 300,
              width: 300,
              alignSelf: 'center',
            }}
          >
            <Loader />
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    genericSnackbar: state.userInterface.genericSnackbar
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleSnackbar: (params) =>
    dispatch({
      type: userInterfaceActions.TOGGLE_GENERIC_SNACKBAR,
        payload: { ...params }
    })
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withStyles(styles)(RequestForQuote)));