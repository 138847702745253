import React, { useState, useEffect } from 'react';
import SGLButton from '../../components/common/Button';
import { Link } from '@material-ui/core';
import { withTheme, withStyles } from '@material-ui/core/styles';
import TermsAndConditionsText from './termsAndConditionsText';
import {getAppInsights} from '../../components/common/AppInsights';
import termsModalStyles from './termsAndConditions.module.css';

const styles = theme => ({
  languageLinks: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 'unset'
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '60%'
    },
  },
  cancelButton:  {
    display: 'inline-block',
    textAlign: 'right',
    paddingRight: '10px',
    marginRight: '20px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      paddingRight: 'unset',
      marginRight: 'unset',
      textAlign: 'unset',
      marginBottom: '20px'
    },
    [theme.breakpoints.between('sm','md')]: {
      paddingRight: '20px',
    }
  },
  textContainer: {
    textAlign: 'justify',
    margin: '40px 20px 50px 50px',
    paddingRight: '30px',
    height: '350px',
    overflowY: 'scroll',
    [theme.breakpoints.down('sm')]: {
      margin: '10px 10px 20px 15px',
      paddingRight: '20px',
    },
  },
});

const acceptTerms = props => {
  const {
    onClose: closeModal,
    onAcceptTerms: acceptTermsCallBack,
  } = props;

  acceptTermsCallBack(true);
  closeModal();
};

const TermsAndConditionsContent = props => {
  useEffect(() => {
    let appInsights = getAppInsights();
    appInsights.trackEvent({ name: 'TermsAndConditionsRender', time: Date.now() });
    appInsights.startTrackPage('TermsAndConditions');

    return () => {
      appInsights.stopTrackPage('TermsAndConditions');
    }
  });
  const {  onClose, classes } = props;
 
  const [englishTermsModal, setEnglishLanguage] = useState(true);

  const switchToFilipino = () => {
    setEnglishLanguage(false);
   };

   const switchToEnglish = () => {
    setEnglishLanguage(true);
   };
   
  return (
    <div className={termsModalStyles.rootContainer}>
      <div className={classes.languageLinks}>
        <Link style={{marginRight: 30}} onClick={switchToFilipino}>Filipino</Link>
        <Link onClick={switchToEnglish}>English</Link>
      </div>
          {englishTermsModal ? (
            <div className={termsModalStyles.termsContainer}>
              <strong>REFERRAL AGREEMENT</strong>
              <div className={classes.textContainer}>
                <TermsAndConditionsText></TermsAndConditionsText>
              </div>
          </div>
          ) : (
            <div className={termsModalStyles.termsContainer}>
              <strong>Mga Tuntunin at Kundisyon </strong>
              <div className={termsModalStyles.textContainer, classes.textContainer}>
                <p>
                Ang pahinang ito ng website ay kasalukuyang ginagawa. Malapit na.</p>
              </div>
            </div>
          )}
     
      <div className={termsModalStyles.buttonsContainer}>
        <div className={termsModalStyles.cancelButton, classes.cancelButton}>
          <SGLButton 
          text={englishTermsModal ? "CANCEL" : "KANSELAHIN"}
           onClick={() => onClose()} 
          />
        </div>
        <div className={termsModalStyles.acceptButton}>
          <SGLButton
            text={englishTermsModal ? "ACCEPT" : "TANGGAPIN"}
            onClick={() => acceptTerms(props)}
          />
        </div>
      </div>
    </div>
  );
};

export default (withTheme(withStyles(styles)(TermsAndConditionsContent)));
