/* eslint-disable import/prefer-default-export */
import { affiliateInfoActions } from '../actions/affiliateInfoActions';

const initialState = {
  affiliateFirstName: "________________",
  affiliateLastName: '',
  affiliateAddress: '___________________'
};

export const affiliateInfoReducer = (state = initialState, action) => {
  switch (action.type) {
   
    case affiliateInfoActions.SET_AFFILIATE_FIRSTNAME: {
      return { ...state, affiliateFirstName: action.payload };
    }
    case affiliateInfoActions.SET_AFFILIATE_LASTNAME: {
      return { ...state, affiliateLastName: action.payload };
    }
    case affiliateInfoActions.SET_AFFILIATE_ADDRESS: {
      return { ...state, affiliateAddress: action.payload };
    }
    default: {
      return state;
    }
  }
};
