import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as Logo } from '../assets/logo.svg';
import { withRouter, Link, NavLink } from 'react-router-dom';
import cx from 'clsx';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import SGLModal from '../components/common/SGLModal'
import LoginPageContent from '../views/login/login'

import MenuIcon from '@material-ui/icons/Menu';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'nowrap'
  },
  topLine: {
    top: 0,
    left: 0,
    width: '100%',
    height: 8,
    background: '#61893C 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    opacity: 1,
  },
  navBar: {
    top: 8,
    left: 0,
    width: '100%',
    height: 85,
    background: '#F9F9F9 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    opacity: 1,
    display: 'inline-flex',
    paddingLeft: 100,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 20,
    },
    display: 'flex',
    // backgroundColor: 'gray'

  },
  navBarLogo: {
    top: 23,
    left: 49,
    width: 221,
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  navLogo: {
    width: '30%',
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '30%',
    },
  },

  navLinks: {
    top: 37,
    left: 436,
    width: '40%',
    height: '100%',
    opacity: 1,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginLeft: '5%',
    paddingRight: '30%',
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '20%',
      marginLeft: 'unset',
      justifyContent: 'right',
      paddingRight: 'unset'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: '80%',
      paddingRight: '5%',
      justifyContent: 'space-evenly',
      marginLeft: '5%',
      fontSize: 'small'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: '50%',
      paddingRight: '5%',
      justifyContent: 'space-evenly',
      marginLeft: '5%',
    },

  },
  menuIcon: {
    [theme.breakpoints.up('sm')]: {
      visibility: 'hidden',
    }
  },
  navItem: {
    flexDirection: 'row',
    fontFamily: 'Lato',
    letterSpacing: 0,
    color: '#707070',
    opacity: 1,
    textDecoration: 'none',
    '&:hover': {
      color: '#40c267',
      fontWeight: 'bolder',
      cursor: 'pointer',
    },
    '&:active': {
      color: '#40c267',
      fontWeight: 'bolder',
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      visibility: 'hidden',
    },
    [theme.breakpoints.up('sm')]: {
      visibility: 'visible',
    },
  },
  navSelected: {
    color: '#40c267',
    fontWeight: 'bolder',
    cursor: 'pointer',
  },
});

const Header = (props) => {
  const [activeLink, setActive,] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loginModal, showLoginModal,] = useState(false);
  const [loginModalMobile, showLoginModalMobile,] = useState(false);

  useEffect(() => {
   
    setActive(props.location.pathname);
  });

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const openLoginModal = () => {
    showLoginModal(!loginModal);
  };

  const openLoginModalMobile = () => {
    showLoginModalMobile(!loginModalMobile);
    setAnchorEl(null);
  };


  const { classes } = props;
  return (
    <>
      <div className={classes.topLine} />
      <div className={classes.root}>
        <div className={classes.navBar}>
          <div className={classes.navLogo}>
            <Link
              // className={cx(classes.navItem, {
              //   [classes.navSelected]: activeLink === '/',
              // })}
              to='/'
            >
              <Logo className={classes.navBarLogo} />
            </Link>

          </div>


          <div className={classes.navLinks}>
            <div className={classes.menuIcon}>
              <MenuIcon aria-controls="simple-menu" aria-haspopup="true"
                onClick={openMenu} />
              <div className={classes.menu}>
                <Menu
                  style={{ top: 50, width: '90%', width: '80%' }}
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={closeMenu}
                >
                  <Link
                    style={{ textDecoration: 'none', color: 'black' }}
                    to={{
                      pathname: '/',
                    }}>
                    <MenuItem onClick={closeMenu}>Home</MenuItem>
                  </Link>

                  <Link
                    style={{ textDecoration: 'none', color: 'black' }}
                    to={{
                      pathname: '/AboutUs',
                    }}>
                    <MenuItem onClick={closeMenu}>Our Company</MenuItem>
                  </Link>

                  <Link
                    style={{ textDecoration: 'none', color: 'black' }}
                    to={{
                      pathname: '/RFQ',
                    }}>
                    <MenuItem onClick={closeMenu}>Request for Proposal</MenuItem>
                  </Link>

                  <Link
                    style={{ textDecoration: 'none', color: 'black' }}
                    to={{
                      pathname: '/ContactUs',
                    }}>
                    <MenuItem onClick={closeMenu} >Contact Us</MenuItem>
                  </Link>
                  <MenuItem onClick={openLoginModalMobile} >Affiliate's Portal</MenuItem>
                </Menu>
              </div>
            </div>
            <div
              className={classes.navItem}
              onClick={() => setActive('home')}
            >
              <Link
                className={cx(classes.navItem, {
                  [classes.navSelected]: activeLink === '/',
                })}
                to='/'
              >
                Home
                </Link>
            </div>
            <div
              className={classes.navItem}
              onClick={() => setActive('aboutUs')}
            >
              <Link
                className={cx(classes.navItem, {
                  [classes.navSelected]: activeLink === '/AboutUs',
                })}
                exact
                to='/AboutUs'
              >
                Our Company
                </Link>
            </div>
            {/* <Link style={{ textDecoration: 'none' }} to='/InProgress'>
                <div className={classes.navItem}>Blogs</div>
              </Link> */}
            <Link style={{ textDecoration: 'none' }} to='/RFQ'>
              <div className={classes.navItem}>Request for proposal</div>
            </Link>
            <Link style={{ textDecoration: 'none' }} to='/ContactUs'>
              <div className={classes.navItem}>Contact us</div>
            </Link>
            <div className={classes.navItem} onClick={openLoginModal}>Affiliate's Portal</div>
          </div>
        </div>
      </div>
        <SGLModal open={loginModal}
         height={400}
         marginTop="15%"
         width={400}
         marginLeft={"35%"}
         borderRadius={0}
          >
            <LoginPageContent
              onClose={showLoginModal}></LoginPageContent>
        </SGLModal>
        <SGLModal open={loginModalMobile}
         height={400}
         marginTop="20%"
         width={'100%'}
         marginLeft={0}
         borderRadius={0}
          >
            <LoginPageContent
              onClose={showLoginModalMobile}></LoginPageContent>

        </SGLModal>

    </>
  );
};

export default withStyles(styles)(withRouter(Header));
