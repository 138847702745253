import React, {useEffect} from 'react';
import PageContent from '../../components/pageContent/pageContent';
import SystemsIntegrationStyles from '../common/common.module.css';
import BannerStyles from './systemsIntegration.module.css';
import {getAppInsights} from '../../components/common/AppInsights';

import { withStyles, withTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

const styles = theme => ({
  bannerText: {
    [theme.breakpoints.between('xs','md')]: {
      fontSize: 'x-large',
      top: 200,
      textAlign: 'center'
    },
  },
  regularTextContainer: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 'small',
      width: '90%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: '14px',
      width: '80%'
    }
  },
});

const SystemsIntegration = (props) => {
  useEffect(() => {
   
    let appInsights = getAppInsights();
    appInsights.trackEvent({ name: 'SystemsIntegrationRender', time: Date.now() });
    appInsights.startTrackPage('SystemsIntegration');

    return () => {
      appInsights.stopTrackPage('SystemsIntegration');
    }
  });
  const { classes } = props;
  return (
    <div className={SystemsIntegrationStyles.container}>
      <div className={BannerStyles.systemsIntegration} />
      <div className={clsx(BannerStyles.bannerText, classes.bannerText)}>Systems Integration</div>
      <PageContent>
        <div className={SystemsIntegrationStyles.contentContainer}>
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(SystemsIntegrationStyles.regularTextContainer, classes.regularTextContainer)}
          >
            Business organizations embrace system integration to improve
            productivity, efficiency, cost, and continuity. <br />
            <br />
            <strong>Communication</strong> is the ultimate reason for systems
            integration.
            <br /> <br />
            Below are some use cases where Systems integration is vital:
          </div>
          <br />
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(SystemsIntegrationStyles.regularTextContainer, classes.regularTextContainer)}
          >
            <ul>
              <li>
                A business can define a certain threshold on its inventory to
                automatically order from a supplier instead of manually checking
                SKUs to determine which items are about to be depleted.
              </li>
              <li>
                A Stock Broker's system consistently gets updated stock prices
                from the Stock Exchange; this speeds up information flow, which
                brings value to the business while reducing operational costs
                for the organization.
              </li>
              <li>
                A business's marketing department would want to integrate with
                other systems to broadcast the latest promotional campaigns.
              </li>
              <li>
                Advertisements need to integrate with other applications to
                offer Ads and track referrals coming from a variety of sources.
              </li>
            </ul>
          </div>
          <br />
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(SystemsIntegrationStyles.regularTextContainer, classes.regularTextContainer)}
          >
            There are lots of other scenarios where Systems integration is
            essential. Some businesses want to implement the latest technologies
            that will give them additional capabilities to manage their daily
            operations. However, it is sometimes hard to attain because the
            legacy system that the company is heavily relying on does not
            support the latest integration strategies.
          </div>
          <br />
          <br />
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(SystemsIntegrationStyles.regularTextContainer, classes.regularTextContainer)}
          >
            <strong>Software Go Live</strong> helps our business partners
            resolve their integration problems as much as possible. Varying
            approaches are depending on the current application setup.
          </div>
          <br />
          <br />
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(SystemsIntegrationStyles.regularTextContainer, classes.regularTextContainer)}
          >
            We highly specialize in implementing systems integration by
            extracting and transforming the business's data and making those
            available as Microservices in a secure, scalable, maintainable, and
            extensible manner.
          </div>
          <br /> <br />
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(SystemsIntegrationStyles.regularTextContainer, classes.regularTextContainer)}
          >
            For instance, an e-commerce application segregated into several
            services like Inventory, Point-of-sale, Helpdesk, and Order
            tracking. If the Point-of-Sale is slowing down because of the large
            number of requests coming in, we can automatically provide
            additional resources or servers for Point-of-sale to load balance
            some user requests without manual intervention. The bright side is
            we only pay for the new server incurred by the point-of-sale instead
            of the cost scaling out the entire e-commerce system when placed in
            another server altogether.
          </div>
          <br /> <br />
          <div
            data-aos='fade-up'
            data-aos-offset='40'
            className={clsx(SystemsIntegrationStyles.regularTextContainer, classes.regularTextContainer)}
          >
            <strong>
              Tell us a story about your integration plans to see how we can be
              of significant contribution.
            </strong>
          </div>
        </div>
      </PageContent>
    </div>
  );
};

export default withTheme(withStyles(styles)(SystemsIntegration));
