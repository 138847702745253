import React, { useEffect } from "react";
import { Route, Switch, Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import pink from '@material-ui/core/colors/blue';

import { ScrollToTop, Header, Body, Footer } from "./layout/index";
import { Home, OurCompany, BusinessValue, InProgress, WebDevelopment, SystemsIntegration, 
  UIUXDesign, RFQ, RFQC, ContactUs, OurProcess, RFPC, Registration, RegistrationComplete, AffiliatesPortal} from "./views";

import "./App.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { getAppInsights } from './components/common/AppInsights';
import TelemetryProvider from './components/common/TelemetryProvider';

const history = createBrowserHistory();

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: blue,
    secondary: pink
  }
})


function App() {

  useEffect(() => {
    AOS.init({
      duration: 2000,
      offset: 300
    });
    AOS.refresh();
  });

  return (
    <div style={{ overflowX: 'hidden' }}>
      <ThemeProvider theme={theme}>
        <Router history={history}>
        <TelemetryProvider instrumentationKey="e3e63799-b40f-4644-901b-470500c0629a" activate={() => { getAppInsights() }}>
          <Header />
          <ScrollToTop>
            <Body>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/AboutUs" component={OurCompany} />
                <Route exact path="/BusinessValue" component={BusinessValue} />
                <Route exact path="/InProgress" component={InProgress} />
                <Route exact path="/WebDevelopment" component={WebDevelopment} />
                <Route exact path="/Integration" component={SystemsIntegration} />
                <Route exact path="/UIUXDesign" component={UIUXDesign} />
                <Route exact path="/RFQ" component={RFQ} />
                <Route exact path="/RFQC" component={RFQC} />
                <Route exact path="/ContactUs" component={ContactUs} />
                <Route exact path="/OurProcess" component={OurProcess} />
                <Route exact path="/RFPC" component={RFPC} />
                <Route exact path="/Registration" component={Registration} />
                <Route exact path="/RegistrationComplete/:code" component={RegistrationComplete} />
                <Route exact path="/AffiliatesPortal" component={AffiliatesPortal} />
                <Route exact path="/AffiliatesPortalTest" component={AffiliatesPortal} />
              </Switch>
            </Body>
          </ScrollToTop>
          <Footer />
          </TelemetryProvider>
        </Router>
      </ThemeProvider>
    </div >

  );
}

export default App;
