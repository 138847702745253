import React from 'react';
import PageContent from '../../components/pageContent/pageContent';
import commonStyles from '../common/common.module.css';
import BannerStyles from './ourProcess.module.css';


import { withStyles, withTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

const styles = theme => ({
  regularTextContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      fontSize: 'small',
      textAlign: 'justify'
    }
  },
  padding: {
    paddingLeft: 40,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0
    }
  }
});

const textContent = (props) => {
  const { classes } = props;
  return (
    <div style={{ paddingLeft: 30 }}>
      <div className={classes.padding} >
        <div
          data-aos='fade-left'
          data-aos-offset='40'
          className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
        >
          Software Go Live strongly believes in the Manifesto for Agile Software
          Development. <br />
          <ul>
            <li>Individuals and interactions over processes and tools</li>
            <li>Working software over comprehensive documentation</li>
            <li>Customer collaboration over contract negotiation</li>
            <li>Responding to change over following a plan</li>
          </ul>
          Hence, the first step in our process involves lots of collaboration
          with our business partner's key person, known as
          <strong> Product Owner</strong>.
          <br /> <br />
          Our<strong> Business Analyst</strong> and the
          <strong> Product Owner</strong> gather the requirements to achieve the
          desired state of the application. <br />
          <br />
          Every identified element goes into the
          <strong> Product Backlog</strong>.
        </div>
        <br />
        <div
          data-aos='fade-right'
          data-aos-offset='40'
          className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
        >
          Our <strong> Solutions Architect</strong> will analyze these
          requirements to make an informed decision on the patterns and
          technologies that are required to support the working software.
        </div>
        <br />
        <div
          data-aos='fade-right'
          data-aos-offset='40'
          className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
        >
          Product Backlog will undergo an estimation process done by the
          <strong> Development Team</strong> with the Solutions Architect's
          help. <br />
          The output of this estimation process produces different User Stories
          with assigned User Story points that represent two things: the
          complexity of the requirement and the time it will take to deliver the
          functionality.
        </div>
        <br />
        <div
          data-aos='fade-right'
          data-aos-offset='40'
          className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
        >
          The Product Owner will prioritize the user stories that the
          <strong> Design Team</strong> will analyze to produce a visual
          representation of the desired output. The approved designs and the
          acceptance criteria defined in each user story will be the source of
          information by the development team.
        </div>
        <br />
        <div
          data-aos='fade-left'
          data-aos-offset='40'
          className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
        >
          Depending on the number of people working on the project, a working
          piece of the software will be deployed incrementally in two to three
          weeks, known as <strong> Sprint</strong>.
        </div>
        <br />
        <div
          data-aos='fade-left'
          data-aos-offset='40'
          className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
        >
          Every Sprint, the Development team develops the functionality and
          creates unit tests to safeguard the code logic as it grows
          tremendously over time.
        </div>
        <br />
        <div
          data-aos='fade-right'
          data-aos-offset='40'
          className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
        >
          On the other hand, our <strong> Quality Assurance</strong> team will
          scrutinize every piece of the functionality to ensure that the
          requirements are met based on the User Story's acceptance criteria.
        </div>
        <br />
        <div
          data-aos='fade-left'
          data-aos-offset='40'
          className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
        >
          At the end of each Sprint, a <strong> Sprint Demo</strong> is
          conducted by the development team to present the working
          functionality.
        </div>
        <br />
        <div
          data-aos='fade-right'
          data-aos-offset='40'
          className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
        >
          The Product Owner <strong> signs it off</strong>, and the working
          functionality will then deployed to the production environment.
        </div>
        <div
          data-aos='fade-right'
          data-aos-offset='40'
          className={commonStyles.regularTextContainer}
        ></div>
        <div
          data-aos='fade-left'
          data-aos-offset='40'
          className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
        >
          The cycle continues until the last piece of functionality goes{' '}
          <strong>LIVE</strong>.
        </div>
      </div>
    </div>
  );
};

export default withTheme(withStyles(styles)(textContent));

