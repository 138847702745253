import { render } from '@testing-library/react';
import React, {Component} from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import ActiveProjectsExpandedComponent from './activeProjectsExpandedComponent';
import _ from 'lodash';


createTheme('solarized', {
    text: {
      primary: '#268bd2',
      secondary: '#2aa198'
    },
    background: {
      default: '#002b36'
    },
    context: {
      background: '#cb4b16',
      text: '#FFFFFF'
    },
    divider: {
      default: '#073642'
    },
    action: {
      button: 'rgba(0,0,0,.54)',
      hover: 'rgba(0,0,0,.08)',
      disabled: 'rgba(0,0,0,.12)'
    }
  });
  
  const customStyles = {
    headRow: {
      style: {
        background: '#83b42b',
      },
    },
    headCells: {
      style: {
        background: '#83b42b',
        color: 'white',
        '& div': {
          color: 'white',
          '&:hover': {
            color: '#4267b2'
          }
        },
        fontWeight: 'bold',
        fontSize: 16
      }
    },
    cells: {
      style: {
        fontSize: 16
      }
    }
  };
  
  const columns = [
      {
        name: 'PROJECT',
        selector: 'projectName',
        sortable: true
      },
      {
        name: 'CONTRACTED PRICE',
        selector: 'contractedPrice',
        sortable: true
      },
      {
        name: 'RUNNING EARNINGS',
        selector: 'runningEarnings',
        sortable: true
      },
  ]

  class ActiveProjects extends Component {
      render(){
        const {incentives} = this.props;
        const allIncentives = []; // all items from the incentives data table
        const allProjects =[]; // all projects without duplicate items
        incentives.forEach(projects => {
          return projects.forEach(item => {
            return allIncentives.push({ 
              projectName: item.projectName, 
              contractedPrice: item.contractedPrice,
              paymentDate: item.paymentDate, 
              paymentChannel: item.paymentChannel,
              amount: item.amount,
              status: item.status
               })
          })
        })

        incentives.forEach(projects => {
          //get only the first object in each array to avoid displaying duplicate projects
          return projects.splice(0,1).forEach(item => {
            return allProjects.push({ 
              projectName: item.projectName, 
              contractedPrice: item.contractedPrice, 
              runningEarnings: item.runningEarnings
           })
          })
        })
      
          return(
            <DataTable
            style={{ marginTop: 20,
                borderRadius: 10,
                boxShadow: '6px 6px 6px #00000029'}}
            title="MY ACTIVE PROJECTS"
            columns={columns}
            data={allProjects}
            customStyles={customStyles}
            highlightOnHover
            expandableRows
            keyField="id"
            striped
            pointerOnHover
            defaultSortField="date"
            pagination
            expandableRowsComponent={
            <ActiveProjectsExpandedComponent 
              allIncentives={allIncentives}
            />}
          />
          )
      }
  }

  export default ActiveProjects;