import React, {useEffect}  from 'react';
import PageContent from '../../components/pageContent/pageContent';
import UIUXStyles from '../common/common.module.css';
import BannerStyles from './UXUIDesign.module.css';
import {getAppInsights} from '../../components/common/AppInsights';

import { withStyles, withTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

const styles = theme => ({
  bannerText: {
    [theme.breakpoints.between('xs','md')]: {
      fontSize: 'x-large',
      top: 200,
      textAlign: 'center'
    },
  },
  regularTextContainer: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 'small',
      width: '90%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: '14px',
      width: '80%'
    }
  },
});

const WebDevelopment = (props) => {
  useEffect(() => {
   
    let appInsights = getAppInsights();
    appInsights.trackEvent({ name: 'UXUIDesignRender', time: Date.now() });
    appInsights.startTrackPage('UXUIDesign');

    return () => {
      appInsights.stopTrackPage('UXUIDesign');
    }
  });
  const { classes } = props;
  return (
    <div className={UIUXStyles.container}>
      <div className={BannerStyles.uiux} />
      <div className={clsx(BannerStyles.bannerText, classes.bannerText)}>
        What do we mean by UX and UI?
      </div>
      <PageContent>
        <div className={UIUXStyles.contentContainer}>
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(UIUXStyles.regularTextContainer, classes.regularTextContainer)}
          >
            User experience design is a human-first way of designing products.
            It encompasses all aspects of the end-users interaction with the
            company, its services, and its products. <br />
            <br />
            User experience designer thinks about how the experience makes the
            user feel, and how easy it is for the user to accomplish their
            desired tasks.
            <br /> <br />
            <strong>Some examples address questions like: </strong>
          </div>
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(UIUXStyles.regularTextContainer, classes.regularTextContainer)}
          >
            <ul>
              <li>
                How convenient is it to process payments when shopping online?
              </li>
              <li>
                How many user actions are required to accomplish a specific
                task?
              </li>
              <li>
                How easy is it to find the things desired by the user when
                navigating through the application?
              </li>
            </ul>
          </div>
          <br />
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(UIUXStyles.regularTextContainer, classes.regularTextContainer)}
          >
            A company's website is the perfect reflection of a business. Hence,
            investing time and effort on good
            <strong> User Interface design </strong> is paramount.
          </div>
          <br />
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(UIUXStyles.regularTextContainer, classes.regularTextContainer)}
          >
            UI design focuses on the usability and overall look and feel of the
            product aiming to present the user interface of an application as
            interactive and intuitive as possible and meticulously analyzing
            every visual, interactive element the user might encounter.
          </div>
          <br /> <br />
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(UIUXStyles.regularTextContainer, classes.regularTextContainer)}
          >
            <strong>
              These are the areas that a good User Interface design takes into
              consideration:
            </strong>
          </div>
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(UIUXStyles.regularTextContainer, classes.regularTextContainer)}
          >
            <ul>
              <li>Icons</li>
              <li>Buttons</li>
              <li>Images</li>
              <li>Typography</li>
              <li>Color schemes</li>
              <li>Multiple device compatibility view a.k.a responsiveness</li>
            </ul>
          </div>
          <br />
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(UIUXStyles.regularTextContainer, classes.regularTextContainer)}
          >
            <strong>Software Go Live UX design goal </strong> is to create
            useful, efficient, consistent, and delightful experiences for the
            user.
          </div>
          <br />
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(UIUXStyles.regularTextContainer, classes.regularTextContainer)}
          >
            We help our partners find ways to provide straightforward user
            experience, identify features the user might need, and create
            wireframes.
          </div>
          <br />
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(UIUXStyles.regularTextContainer, classes.regularTextContainer)}
          >
            Our <strong>User Interface design</strong> team then interprets
            these wireframes with visual and interactive elements, doing design
            research for a specific product, analyzing branding, graphics,
            interactivity, animations, and adaptation to multiple devices screen
            sizes.
          </div>
          <br />
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(UIUXStyles.regularTextContainer, classes.regularTextContainer)}
          >
            Our UX and UI team works hand in hand with our frontend developers
            to implement functionalities that integrate with application
            services to execute business logic for a completely functional web
            application.
          </div>
        </div>
      </PageContent>
    </div>
  );
};

export default withTheme(withStyles(styles)(WebDevelopment));
