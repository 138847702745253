import React, {useEffect} from 'react';
import { StickyContainer, Sticky } from "react-sticky";

import PageContent from '../../components/pageContent/pageContent';
import commonStyles from '../common/common.module.css';
import BannerStyles from './ourProcess.module.css';
import OurProcessContent from './textContent';
import SvgImage from '../../components/common/SvgImage';
import SvgProcess from '../../assets/process.svg';
import {getAppInsights} from '../../components/common/AppInsights';

import { withStyles, withTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

const styles = theme => ({
  bannerText: {
      [theme.breakpoints.between('xs','md')]: {
        fontSize: 'x-large',
        top: 220,
        textAlign: 'center'
      }
  },
  column: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 'small',
    }
  },
  row: {
    [theme.breakpoints.down('sm')]: {
      display: 'unset',
      paddingLeft: 0,
      paddingTop: 30
    },
    [theme.breakpoints.up('md')]: {
      width: 'unset'
    }
  },
  regularTextContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      textAlign: 'justify'
    }
  },
  imageColumn: {
    paddingTop: 60,
    [theme.breakpoints.up('md')]: {
      visibility: 'hidden',
    },
  },
  stickyImageColumn: {
    [theme.breakpoints.between('xs','md')]: {
      visibility: 'hidden',
      height: 0
    },
    [theme.breakpoints.between('md','xl')]: {
      visibility: 'visible'
    },
  },

  
});



const WebDevelopment = (props) => {
  useEffect(() => {
   
    let appInsights = getAppInsights();
    appInsights.trackEvent({ name: 'OurProcessRender', time: Date.now() });
    appInsights.startTrackPage('OurProcess');

    return () => {
      appInsights.stopTrackPage('OurProcess');
    }
  });

  const { classes } = props;
  return (
    <div className={commonStyles.container}>
      <div className={BannerStyles.webDevelopment} />
      <div className={clsx(BannerStyles.bannerText, classes.bannerText)}>Our Process</div>
      <StickyContainer style={{ height: "auto", width: "90%"}}>
        <div className={clsx(BannerStyles.row, classes.row)}>
        <div className={clsx(BannerStyles.column, classes.column)}>
          {/* had to duplicate the imageColumn to hide the sticky element on smaller devices */}
          <div className={classes.stickyImageColumn}>
            <Sticky>{({ style }) => 
              <div style={style}>
                 <div style={{height: 40}}></div>
                <SvgImage id='svgWorkNow' svg={SvgProcess} />
                <div style={{height: 40}}></div>
              </div>}
            </Sticky>
          </div>
          <div className={classes.imageColumn}>
            <SvgImage id='svgWorkNow' svg={SvgProcess} />
          </div>
        </div>
        <div className={BannerStyles.column}>
          <OurProcessContent className={BannerStyles.textColumn} />
      </div>
      </div>
    </StickyContainer>
  </div>
  );
};


export default withTheme(withStyles(styles)(WebDevelopment));
