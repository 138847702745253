import React, { useState, useEffect } from 'react';
import PageContent from '../../components/pageContent/pageContent';
import { withTheme, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import RegistrationStyles from '../common/common.module.css';
import { ReactComponent as Loader } from '../../assets/svg-loader.svg';
import axios from 'axios';
import { connect } from 'react-redux';
import BannerStyles from './registration.module.css';
import ActionNotification from '../../components/common/Snackbar';
import { TextField, Button, FormControlLabel,FormHelperText, Checkbox, Link, MenuItem } from '@material-ui/core';

import CountrySelect from '../../components/common/CountryList';
import {getAppInsights} from '../../components/common/AppInsights';
import ReCAPTCHA from 'react-google-recaptcha';
import SGLModal from '../../components/common/SGLModal';
import TermsAndConditionsContent from '../termsAndConditions/termsAndConditions';
import PasswordStrengthBar from 'react-password-strength-bar';

import { userInterfaceActions } from '../../store/actions/userInterfaceActions';
import { affiliateInfoActions } from '../../store/actions/affiliateInfoActions';

const shortid = require('shortid');

const styles = theme => ({
  column: {
    [theme.breakpoints.down('sm')]: {
      flex: 'unset'
    },
  },
  bannerText: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 'x-large',
     top: 210
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: 'x-large',
      textAlign: 'center',
      marginTop: 'unset'
    },
    [theme.breakpoints.between('md', 'lg')]: {
    }
  },
  row: {
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    },
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      flexDirection: 'row',
      marginLeft: 10
    },
    '& button': {
      marginTop: 20,
      [theme.breakpoints.up('sm')]: {
        marginTop: 0,
      },
    }
  },
  salutationContainer: {
    padding: 10,
    [theme.breakpoints.down('sm')]: {
      display: 'flex'
    },
  },
  salutation: {
    width: 150,
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  }
});

const salutations = [
  {
    value: 'Mr.',
    label: 'Mr.'
  },
  {
    value:"Ms.",
    label:"Ms."
  },
];

const Registration = (props) => {
  useEffect(() => {
   
    let appInsights = getAppInsights();
    appInsights.trackEvent({ name: 'RegistrationRender', time: Date.now() });
    appInsights.startTrackPage('Registration');

    return () => {
      appInsights.stopTrackPage('Registration');
    }
  });

  const { classes, setAffiliateFirstName, setAffiliateLastName, setAffiliateAddress } = props;
  let currentState = {};
  const submitRequest = (state) => {
    let valid = true;

    if (!state.firstName.value) {
      valid = false;
      currentState = {
        ...currentState,
        firstName: { value: '', validation: 'First name is required.' },
      };
    }

    if (!state.lastName.value) {
      valid = false;
      currentState = {
        ...currentState,
        lastName: { value: '', validation: 'Last name is required.' },
      };
    }

    if (!state.address.value) {
      valid = false;
      currentState = {
        ...currentState,
        address: { value: '', validation: 'Address is required.' },
      };
    }

    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.email.value)
    ) {
      valid = false;
      currentState = {
        ...currentState,
        email: { value: '', validation: 'Invalid email address.' },
      };
    }

    if (!state.phone.value) {
      valid = false;
      currentState = {
        ...currentState,
        phone: { value: '', validation: 'Please input your phone number.' },
      };
    }

    if (!state.captcha.value) {
      valid = false;
      currentState = {
        ...currentState,
        captcha: {
          value: '',
          validation: 'Please verify that you are human.',
        },
      };
    }

    if (!termsAccepted) {
      valid = false;
      currentState = {
        ...currentState,
        termsAccepted: { value: false, validation: 'To submit your registration, you must accept the Terms and Conditions.' },
      };
    }
    
    if (!state.paypal.value && !state.bankName.value && !state.gCash.value) {
      valid = false;
      currentState = {
        ...currentState,
        bankName: { value: '', validation: 'Bank info is required if Paypal and GCash were not provided.' },
        paypal: { value: '', validation: 'Paypal is required.' },
        gCash: { value: '', validation: 'GCash is required.'}
      };
    }

    if ( state.bankName.value && !state.accountName.value) {
      valid = false;
      currentState = {
        ...currentState,
        accountName: { value: '', validation: 'Account name is required.' },
      };
    }

  if ( state.bankName.value && !state.accountNumber.value) {
      valid = false;
      currentState = {
        ...currentState,
        accountNumber: { value: '', validation: 'Account number is required.' },
      };
    }

    if (!salutation) {
      valid = false;
      currentState = {
        ...currentState,
        salutation: { value: '', validation: 'Salutation is required' },
      };
    }

    if (!state.password.value) {
      valid = false;
      currentState = {
        ...currentState,
        password: { value: '', validation: 'Password is required.' },
      };
    }

    if (!state.confirmPassword.value) {
      valid = false;
      currentState = {
        ...currentState,
        confirmPassword: { value: '', validation: 'Confirm password is required.' },
      };
    }

    if (!valid) {
      setState({ ...state, ...currentState });
    }

    if (valid) {
      setState({
        ...state,
        showLoading: { value: true },
      });

      const dataParams = {
        Salutation: state.salutation.value,
        FirstName: state.firstName.value,
        LastName: state.lastName.value,
        Address: state.address.value,
        Country: state.country.value,
        Email: state.email.value,
        PhoneNumber: state.phone.value,
        AlternatePhoneNumber: state.phone2.value,
        BankName: state.bankName.value,
        AccountName: state.accountName.value,
        AccountNumber: state.accountNumber.value,
        Password: state.password.value,
        ConfirmPassword: state.confirmPassword.value,
        Paypal: state.paypal.value,
        GCash: state.gCash.value
      };

      const { toggleSnackbar} = props;
     
      setTimeout(() => {
        localStorage.setItem('partner', state.firstName.value);
        axios
          .post(
            'https://apimgtsgl.azure-api.net/sglbe/OnRegistrationReceived',
            JSON.stringify(dataParams),
              {
                headers: {
                  "Ocp-Apim-Subscription-Key": "fe765ecf6cbc46c5b21f52ceb4a86843;product=sglapi",
                  "Ocp-Apim-Trace": true
                },
                timeout: 30000
              }
          )
          .then(response => {
            console.log(response);
            if (response.status === 200){
              window.location = `/RegistrationComplete/${response.data}`;
            }
          })
          .catch(() => {
            toggleSnackbar({ open: true, message:  'This machine currently has problems communicating to the server. Please try again later.', success: false });
          })
          .finally(() => {
            setState({
              ...state,
              showLoading: { value: false },
            });
           
          });
      }, 1000);
    }
  };

  const [state, setState] = useState({
    firstName: { value: '', validation: '' },
    lastName:  { value: '', validation: '' },
    address: { value: '', validation: '' },
    country: { value: '', validation: '' },
    email: { value: '', validation: '' },
    phone: { value: '', validation: '' },
    phone2: { value: '', validation: '' },
    captcha: { value: '', validation: '' },
    showLoading: { value: false },
    termsAccepted: { value: false, validation: '' },
    showTermsModal: { value: false },
    bankName: { value: '', validation: '' },
    accountName: { value: '', validation: '' },
    accountNumber: { value: '', validation: '' },
    salutation: { value: '', validation: '' },
    password: { value: '', validation: '' },
    confirmPassword: { value: '', validation: '' },
    paypal: { value: '', validation: '' },
    gCash: { value: '', validation: '' }
  });
  const [attachment, setAttachment] = useState();
  const [showTermsModal, toggleModal] = useState();
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [salutation, setSalutation]= useState("");

  const onCountryChange = (value) => {
    setState({
      ...state,
      country: { value: value, validation: '' },
    });
  };

  const onCaptchaChanged = (param) => {
    setState({
      ...state,
      captcha: { value: param, validation: '' },
    });
  };

  const toggleTermsModal = () => {
   toggleModal(!showTermsModal);
  };
  

  const acceptTermsChangeHandler = (event) => {
    setTermsAccepted(event.target.checked);
    setState({
      ...state,
      termsAccepted:  {  validation: event.target.checked === true && '' }
    });
  };

  const acceptTermsCallBackHandler = (param) => {
    setTermsAccepted(param);
    setState({
      ...state,
      termsAccepted:{  validation:  param === true && '' }
    });
  };

  const handleChangeSalutation = (event) => {
    setSalutation(event.target.value);
    setState({
      ...state,
      salutation:  { value: event.target.value, validation: '' }
    });
  };

  const {
    firstName,
    lastName,
    address,
    country,
    email,
    phone,
    phone2,
    captcha,
    showLoading,
    bankName,
    accountName,
    accountNumber,
    password,
    confirmPassword,
    paypal,
    gCash
  } = state;

  const { genericSnackbar, toggleSnackbar} = props;

  return (
    <>
      <form>
        <div className={RegistrationStyles.container}>
          <div className={BannerStyles.BusinessPartner} />
          <div className={clsx(BannerStyles.bannerText, classes.bannerText)}>Affiliates Registration</div>
          <PageContent>
            <div
              style={{
                alignContent: 'center',
                width: '80%',
                boxSizing: 'border-box',
              }}
            >
              <div>Tell us a bit about you:</div>
              <div className={clsx(BannerStyles.row, classes.row)}>
              <div className={classes.salutationContainer}>
              <TextField
               className={classes.salutation}
                  select
                  label="Salutation"
                  value={state.salutation.value}
                  onChange={handleChangeSalutation}
                  helperText={state.salutation.validation}
                  error={state.salutation.validation !== ''}
           
                  variant="outlined"
                >
                  {salutations.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                </div>
                <div className={clsx(BannerStyles.column, classes.column)}>
    
                  <TextField
                    required
                    id='outlined-required'
                    label='First Name'
                    variant='outlined'
                    error={firstName.validation !== ''}
                    helperText={firstName.validation}
                    value={firstName.value}
                    onChange={(e) => {
                      setState({
                        ...state,
                        firstName: { value: e.target.value, validation: '' },
                      });
                      setAffiliateFirstName(e.target.value)
                    }}
                  />
                </div>
                <div className={BannerStyles.column}>
                  <TextField
                    required
                    id='outlined-required'
                    label='Last Name'
                    variant='outlined'
                    value={lastName.value}
                    error={lastName.validation !== ''}
                    helperText={lastName.validation}
                    onChange={(e) => {
                      setState({
                        ...state,
                        lastName: { value: e.target.value, validation: '' },
                      });
                      setAffiliateLastName( e.target.value)
                    }}
                  />
                </div>
              </div>
              <div className={clsx(BannerStyles.row, classes.row)}>
                <div className={clsx(BannerStyles.column, classes.column)}>
                  <TextField
                    required
                    id='outlined-required'
                    label='Address'
                    variant='outlined'
                    value={address.value}
                    error={address.validation !== ''}
                    helperText={address.validation}
                    onChange={(e) =>{
                      setState({
                        ...state,
                        address: { value: e.target.value, validation: '' },
                      });
                      setAffiliateAddress( e.target.value)
                    }}
                  />
                </div>
                <div className={BannerStyles.column}>
                  {CountrySelect(country.value, onCountryChange)}
                </div>
              </div>

              <div className={clsx(BannerStyles.row, classes.row)}>
                <div className={BannerStyles.column}>
                <TextField
                    required
                    id='outlined-required'
                    label='Phone number'
                    variant='outlined'
                    value={phone.value}
                    error={phone.validation !== ''}
                    helperText={phone.validation}
                    onChange={(e) =>
                      setState({
                        ...state,
                        phone: { value: e.target.value, validation: '' },
                      })
                    }
                  />
                </div>
                <div className={BannerStyles.column}>
                <TextField
                    id='outlined-required'
                    label='Alternate Phone number'
                    variant='outlined'
                    value={phone2.value}
                    error={phone2.validation !== ''}
                    helperText={phone2.validation}
                    onChange={(e) =>
                      setState({
                        ...state,
                        phone2: { value: e.target.value, validation: '' },
                      })
                    }
                  />
                </div>
              </div>

              <div className={clsx(BannerStyles.row, classes.row)}>
                <div className={clsx(BannerStyles.column, classes.column)}>
                <TextField
                    required
                    id='outlined-required'
                    label='Email Address'
                    variant='outlined'
                    value={email.value}
                    error={email.validation !== ''}
                    helperText={email.validation}
                    onChange={(e) => {
                      setState({
                        ...state,
                        email: { value: e.target.value, validation: '' },
                      });
                    }}
                  />
                </div>
                <div className={BannerStyles.column}>
                <TextField
                    type="password"
                    required
                    id='outlined-required'
                    label='Password'
                    variant='outlined'
                    value={password.value}
                    error={password.validation !== ''}
                    helperText={password.validation}
                    onChange={(e) =>
                      setState({
                        ...state,
                        password: { value: e.target.value, validation: '' },
                      })
                    }
                  />
             <PasswordStrengthBar password={password.value}>      </PasswordStrengthBar>
                </div>
                <div className={BannerStyles.column}>
                <TextField
                    required
                    type="password"
                    id='outlined-required'
                    label='Confirm Password'
                    variant='outlined'
                    value={confirmPassword.value}
                    error={confirmPassword.validation !== ''}
                    helperText={confirmPassword.validation}
                    onChange={(e) =>
                      setState({
                        ...state,
                        confirmPassword: { 
                          value: e.target.value, 
                          validation: e.target.value ===  state.password.value ? '' :  "Confirm password should match the password."
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div>Where can we send your profit share?</div>
              <div className={clsx(BannerStyles.row, classes.row)}>
                <div className={clsx(BannerStyles.column, classes.column)}>
                <TextField
                    id='outlined-required'
                    label='Paypal Email'
                    variant='outlined'
                    value={paypal.value}
                    error={paypal.validation !== ''}
                    helperText={paypal.validation}
                    onChange={(e) => {
                      setState({
                        ...state,
                        gCash: { value: gCash.value, validation: "" },
                        paypal: { value: e.target.value, validation: "" },
                        bankName: {value: bankName.value, validation: ""},
                        accountNumber: {value: accountNumber.value, validation: ""},
                        accountName: {value: accountName.value,validation: ""}
                      });
                    }}
                  />
                </div>
                <div className={clsx(BannerStyles.column)}>
                <TextField
                    id='outlined-required'
                    label='GCash'
                    variant='outlined'
                    value={gCash.value}
                    error={gCash.validation !== ''}
                    helperText={gCash.validation}
                    onChange={(e) => {
                      setState({
                        ...state,
                        gCash: { value: e.target.value, validation: "" },
                        paypal: { value: paypal.value, validation: "" },
                        bankName: {value: bankName.value, validation: ""},
                        accountNumber: {value: accountNumber.value, validation: ""},
                        accountName: {value: accountName.value,validation: ""}
                      });
                    }}
                  />
                </div>
                </div>
                <div>(Optional)</div>
                <div className={clsx(BannerStyles.row, classes.row)}>
                <div className={clsx(BannerStyles.column, classes.column)}>
                <TextField
                    id='outlined-required'
                    label='Bank Name'
                    placeholder="Local Bank in the Philippines"
                    variant='outlined'
                    value={bankName.value}
                    error={bankName.validation !== ''}
                    helperText={bankName.validation}
                    onChange={(e) => {
                      setState({
                        ...state,
                        bankName: { value: e.target.value, validation: ""},
                        paypal: {value: paypal.value, validation: ""},
                        gCash: { value: gCash.value, validation: "" }
                      });
                    }}
                  />
                </div>
                <div className={clsx(BannerStyles.column, classes.column)}>
                <TextField
                    id='outlined-required'
                    label='Account Name'
                    variant='outlined'
                    value={accountName.value}
                    error={accountName.validation !== ''}
                    helperText={accountName.validation}
                    onChange={(e) => {
                      setState({
                        ...state,
                        accountName: { value: e.target.value, validation: '' },
                      });
                    }}
                  />
                </div>
                <div className={BannerStyles.column}>
                <TextField
                    id='outlined-required'
                    label='Account number'
                    variant='outlined'
                    value={accountNumber.value}
                    error={accountNumber.validation !== ''}
                    helperText={accountNumber.validation}
                    onChange={(e) =>
                      setState({
                        ...state,
                        accountNumber: { value: e.target.value, validation: '' },
                      })
                    }
                  />
                </div>
              </div>
              <div style={{textAlign: 'center'}}> 
              <FormControlLabel
                    control={
              <Checkbox
              disabled
               checked={termsAccepted}
               onChange={acceptTermsChangeHandler}
               value="termsAccepted" />
              }
              label= "I accept Affiliate's Terms and Conditions."
          />
          <Link onClick={toggleTermsModal}>View Terms</Link>
          <FormHelperText style={{color: 'red', textAlign: "center"}}>{state.termsAccepted.validation}</FormHelperText>
          </div>
              <div className={clsx(BannerStyles.submitButton, classes.submitButton)}>
                <ReCAPTCHA
                  sitekey='6LeWiKcZAAAAALqgxdyrlo1uTRGHK1aTiGDoRmpF'
                  onChange={onCaptchaChanged}
                />
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => submitRequest(state)}
                >
                  Submit
                </Button>
              </div>
              {captcha.validation && (
                <div
                  className={BannerStyles.submitButton}
                  style={{ color: 'red' }}
                >
                  {captcha.validation}
                </div>
              )}
            </div>
          </PageContent>
        </div>
      </form>
      {showLoading.value && (
        <div className={RegistrationStyles.loader}>
          <div
            style={{
              height: 300,
              width: 300,
              alignSelf: 'center',
            }}
          >
            <Loader />
          </div>
        </div>
      )}
       {genericSnackbar.open && (
          <ActionNotification
            open={genericSnackbar.open}
            message={genericSnackbar.message}
            success={genericSnackbar.success}
            onClose={toggleSnackbar}
          />
        )}
        <SGLModal open={showTermsModal}
         height={600}
         marginTop="5%"
         width="90%"
         marginLeft="5%"
          >
          <TermsAndConditionsContent
            onAcceptTerms={acceptTermsCallBackHandler}
            onClose={toggleTermsModal}
          />
        </SGLModal>
    </>
    
  );
};

const mapStateToProps = state => {
  return {
    genericSnackbar: state.userInterface.genericSnackbar
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleSnackbar: (params) =>
    dispatch({
      type: userInterfaceActions.TOGGLE_GENERIC_SNACKBAR,
        payload: { ...params }
    }),
    setAffiliateFirstName: affiliateFirstName =>
    dispatch({
      type: affiliateInfoActions.SET_AFFILIATE_FIRSTNAME,
      payload: affiliateFirstName
    }),
    setAffiliateLastName: affiliateLastName =>
    dispatch({
      type: affiliateInfoActions.SET_AFFILIATE_LASTNAME,
      payload: affiliateLastName
    }),
    setAffiliateAddress: affiliateAddress =>
    dispatch({
      type: affiliateInfoActions.SET_AFFILIATE_ADDRESS,
      payload: affiliateAddress
    }),
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(withStyles(styles)(Registration)));

