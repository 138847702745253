import React, { createRef, useState, useEffect, useCallback } from 'react';
import PageContent from '../../components/pageContent/pageContent';
import { withTheme, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import RFQStyles from '../common/common.module.css';
import { ReactComponent as Loader } from '../../assets/svg-loader.svg';
import axios from 'axios';
//import Button from '../../components/common/Button';
import BannerStyles from './contactUs.module.css';
import { TextField, Button } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import CountrySelect from '../../components/common/CountryList';
import {getAppInsights} from '../../components/common/AppInsights';
import ReCAPTCHA from 'react-google-recaptcha';
const shortid = require('shortid');

const styles = theme => ({
  row: {
    [theme.breakpoints.down('sm')]: {
      display: 'unset'
    },
  },
  bannerText: {
    [theme.breakpoints.between('xs','md')]: {
      fontSize: 'x-large',
      top: 200,
      textAlign: 'center'
    },
  },
  submitButton: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width:'95%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      flexDirection: 'row',
      marginLeft: 10
    },
    '& button': {
      marginTop: 20,
      [theme.breakpoints.up('sm')]: {
        marginTop: 0,
      },
    }
  }
});

const RequestForQuote = (props) => {
  useEffect(() => {
   
    let appInsights = getAppInsights();
    appInsights.trackEvent({ name: 'ContactUsRender', time: Date.now() });
    appInsights.startTrackPage('ContactUs');

    return () => {
      appInsights.stopTrackPage('ContactUs');
    }
  });

  const { classes } = props;
  let currentState = {};
  const submitRequest = (state) => {
    let valid = true;

    if (!state.name.value) {
      valid = false;
      currentState = {
        ...currentState,
        name: { value: '', validation: 'We are glad to know more about you.' },
      };
    }

    if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.email.value)
    ) {
      valid = false;
      currentState = {
        ...currentState,
        email: { value: '', validation: 'Invalid email address.' },
      };
    }

    if (!state.phone.value) {
      valid = false;
      currentState = {
        ...currentState,
        phone: { value: '', validation: 'Please input your phone number.' },
      };
    }

    if (!state.companyName.value) {
      valid = false;
      currentState = {
        ...currentState,
        companyName: {
          value: '',
          validation: 'Please input your company name.',
        },
      };
    }

    if (!state.message.value) {
      valid = false;
      currentState = {
        ...currentState,
        message: {
          value: '',
          validation: 'Please let us know your thoughts.',
        },
      };
    }

    if (!state.captcha.value) {
      valid = false;
      currentState = {
        ...currentState,
        captcha: {
          value: '',
          validation: 'Please verify that you are human.',
        },
      };
    }

    if (!valid) {
      setState({ ...state, ...currentState });
    }

    if (valid) {
      setState({
        ...state,
        showLoading: { value: true },
      });

      const dataParams = {
        Name: state.name.value,
        Email: state.email.value,
        Phone: state.phone.value,
        CompanyName: state.companyName.value,
        Message: state.message.value,
        Country: state.country.value,
        ReferenceID: shortid.generate().toUpperCase(),
      };

      setTimeout(() => {
        axios
          .post(
            'https://prod-26.southeastasia.logic.azure.com:443/workflows/cb9c8aba6b7348c2a500c1688126f31c/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=8UrW8HnJ4hCeX_AlRvZtbFnH7zvxVZBZdJxtL0lYx6A',
            JSON.stringify(dataParams),
            {
              headers: {
                'Content-Type': 'application/json',
              },
              timeout: 30000,
              data: {}, //Note: Empty data is a work around for axios to prevent removing the content type header
            }
          )
          .then((response) => {
            console.log(response);
          })
          .finally(() => {
            setState({
              ...state,
              showLoading: { value: false },
            });
            window.location = '/RFQC';
          });
      }, 1000);
    }
  };
  const [state, setState] = useState({
    name: { value: '', validation: '' },
    email: { value: '', validation: '' },
    phone: { value: '', validation: '' },
    country: { value: '', validation: '' },
    companyName: { value: '', validation: '' },
    message: { value: '', validation: '' },
    captcha: { value: '', validation: '' },
    showLoading: { value: false },
  });

  const [attachment, setAttachment] = useState();

  const onDrop = useCallback((acceptedFiles) => {
    setAttachment(acceptedFiles[0]);
  }, []);
  const onCountryChange = (value) => {
    setState({
      ...state,
      country: { value: value, validations: '' },
    });
  };

  const onCaptchaChanged = (param) => {
    setState({
      ...state,
      captcha: { value: param, validations: '' },
    });
  };

  const {
    name,
    email,
    phone,
    companyName,
    message,
    captcha,
    showLoading,
  } = state;
  return (
    <>
      <form>
        <div className={RFQStyles.container}>
          <div className={BannerStyles.RFQ} />
          <div className={clsx(BannerStyles.bannerText, classes.bannerText)}>Contact Us</div>
          <PageContent>
            <div
              style={{
                alignContent: 'center',
                width: '80%',
                boxSizing: 'border-box',
              }}
            >
              <div className={clsx(BannerStyles.row, classes.row)}>
                <div className={BannerStyles.column}>
                  <TextField
                    required
                    id='outlined-required'
                    label='Your Name'
                    variant='outlined'
                    error={name.validation !== ''}
                    helperText={name.validation}
                    value={name.value}
                    onChange={(e) =>
                      setState({
                        ...state,
                        name: { value: e.target.value, validation: '' },
                      })
                    }
                  />
                </div>
                <div className={BannerStyles.column}>
                  <TextField
                    required
                    id='outlined-required'
                    label='Email Address'
                    variant='outlined'
                    value={email.value}
                    error={email.validation !== ''}
                    helperText={email.validation}
                    onChange={(e) => {
                      setState({
                        ...state,
                        email: { value: e.target.value, validation: '' },
                      });
                    }}
                  />
                </div>
              </div>
              <div className={clsx(BannerStyles.row, classes.row)}>
                <div className={BannerStyles.column}>
                  <TextField
                    required
                    id='outlined-required'
                    label='Phone number'
                    variant='outlined'
                    value={phone.value}
                    error={phone.validation !== ''}
                    helperText={phone.validation}
                    onChange={(e) =>
                      setState({
                        ...state,
                        phone: { value: e.target.value, validation: '' },
                      })
                    }
                  />
                </div>
                <div className={BannerStyles.column}>
                  <TextField
                    required
                    id='outlined-required'
                    label='Company Name'
                    variant='outlined'
                    value={companyName.value}
                    error={companyName.validation !== ''}
                    helperText={companyName.validation}
                    onChange={(e) =>
                      setState({
                        ...state,
                        companyName: { value: e.target.value, validation: '' },
                      })
                    }
                  />
                </div>
              </div>

              <div className={BannerStyles.row}>
                <div className={BannerStyles.column}>
                  <TextField
                    id='outlined-required'
                    label='Message'
                    variant='outlined'
                    multiline
                    rows={5}
                    value={message.value}
                    onChange={(e) =>
                      setState({
                        ...state,
                        message: { value: e.target.value },
                      })
                    }
                  />
                </div>
              </div>
              <div className={clsx(BannerStyles.submitButton,classes.submitButton)}>
                <ReCAPTCHA
                  sitekey='6LeWiKcZAAAAALqgxdyrlo1uTRGHK1aTiGDoRmpF'
                  onChange={onCaptchaChanged}></ReCAPTCHA>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => submitRequest(state)}
                >
                  Submit
                </Button>
              
              </div>
              {captcha.validation && (
                <div
                  className={BannerStyles.submitButton}
                  style={{ color: 'red' }}
                >
                  {captcha.validation}
                </div>
              )}
            </div>
          </PageContent>
        </div>
      </form>
      {showLoading.value && (
        <div className={RFQStyles.loader}>
          <div
            style={{
              height: 300,
              width: 300,
              alignSelf: 'center',
            }}
          >
            <Loader />
          </div>
        </div>
      )}
    </>
  );
};

export default withTheme(withStyles(styles)(RequestForQuote));
