import { pink } from '@material-ui/core/colors';
import React, {Component} from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import _ from 'lodash';

const columns = [
  {       
   name: 'PAYMENT DATE',
  selector: 'paymentDate',
  sortable: true
},
{
  name: 'PAYMENT CHANNEL',
  selector: 'paymentChannel',
  sortable: true
},
{       
  name: 'AMOUNT',
 selector: 'amount',
 sortable: true
},
{       
  name: 'STATUS',
 selector: 'status',
 sortable: true
}
]

const customStyles = {
  rows: {
    style: {
      '&:not(:last-of-type)': {
        borderBottomWidth: '0px',
      },
    },
  },
};
  class ActiveProjectsExpandedComponent extends Component {
    render(){
    const { allIncentives, data} = this.props;
    let paymentDetails = _.filter(allIncentives, { 'projectName': data.projectName }); 
        return(
          <DataTable
          style={{ marginLeft: 50, marginBottom: 50 , width: '93%'}}
          columns={columns}
          data={paymentDetails}
          customStyles={customStyles}
          highlightOnHover
          keyField="id"
          striped
          pointerOnHover
          defaultSortField="date"
        />
        )
    }
  }

  export default ActiveProjectsExpandedComponent;