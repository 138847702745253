import React, {useEffect} from 'react';
import PageContent from '../../components/pageContent/pageContent';
import commonStyles from '../common/common.module.css';
import BannerStyles from './webdevelopment.module.css';
import {getAppInsights} from '../../components/common/AppInsights';

import { withStyles, withTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

const styles = theme => ({
  bannerText: {
    [theme.breakpoints.between('xs','md')]: {
      fontSize: 'x-large',
      top: 200,
      textAlign: 'center'
    },
  },
  regularTextContainer: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 'small',
      width: '90%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: '14px',
      width: '80%'
    }
  },
});

const WebDevelopment = (props) => {
  useEffect(() => {
    let appInsights = getAppInsights();
    appInsights.trackEvent({ name: 'WebDevelopmentRender', time: Date.now() });
    appInsights.startTrackPage('WebDevelopment');

    return () => {
      appInsights.stopTrackPage('WebDevelopment');
    }
  });
  const { classes } = props;
  return (
    <div className={commonStyles.container}>
      <div className={BannerStyles.webDevelopment} />
      <div className={clsx(BannerStyles.bannerText, classes.bannerText)}>Web Application Development</div>
      <PageContent>
        <div className={commonStyles.contentContainer}>
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
          >
            If you want your company to be successful in the modern marketplace,
            a professional website with good user experience is a total
            game-changer. <br />
            <br />
            Your website is the
            <strong> image of your business</strong>, supporting all of your
            digital marketing efforts.
            <br /> <br />
            Your website is the perfect place to send customers when they want
            to make a purchase or learn more about a particular product or
            service your company offers.
          </div>
          <br />
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
          >
            A study made by the Ecommerce Foundation revealed that 88 percent of
            consumers research products online before buying in-store.
          </div>
          <br />
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
          >
            Most consumers are looking online for information that will help
            them make an informed decision before availing products or services.
          </div>
          <br />
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
          >
            This buying behavior highlights the importance of a website for
            today’s businesses.
          </div>
          <br />
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
          >
            A website is also a place where your employees go in to perform
            their daily responsibilities. With the recent hit of the COVID-19
            pandemic, many businesses struggled due to lockdown to prevent
            spreading the disease. The government ordered work from home, but
            this kind of work setup does not apply to all companies. Many
            businesses started to realize the importance of a website to support
            their operation <strong>to gain business continuity.</strong>
          </div>
          <br />
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
          >
            In Software Go Live, we help our partners in establishing their
            website based on their specific business needs.
          </div>
          <br />
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
          >
            Our priority when developing a website is the high availability,
            maintainability, and resiliency of the application. Traditionally,
            there is a considerable upfront cost when a company wants to
            establish a website. These include high-speed internet connectivity,
            powerful servers to host the application, air-conditioned space to
            store the servers, and skilled professionals to maintain the network
            and to ensure that the application is running on the desired state.
            To address these concerns, we build our websites on top of cloud
            technologies provided by two leading cloud technology providers
            Microsoft Azure and Amazon Web Services.
          </div>
          <br />
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
          >
            We create applications strictly adhering to industry standards of
            modern web development tools and practices. And we apply
            technologies used by globally renowned brands in social media
            platforms, live streaming, and other online marketplace companies,
            to name a few.
          </div>
          <br />
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
          >
            <strong>
              Please see the list of services below where we can help the most:
            </strong>
          </div>
          <div
            data-aos='fade-right'
            data-aos-offset='40'
            className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
          >
            <ul>
              <li>E-commerce Web Application</li>
              <li>Back-office Web Applications</li>
              <li>Single Page Web Applications</li>
              <li>Serverless Web Applications</li>
              <li>Windows Applications</li>
              <li>Windows Services Applications</li>
              <li>Web Services </li>
            </ul>
          </div>
          <div
            data-aos='fade-left'
            data-aos-offset='40'
            className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
          >
            <strong>Our core competencies:</strong>
            <ul>
              <li>Angular</li>
              <li>React JS</li>
              <li>Node JS</li>
              <li>.NET Core</li>
              <li>Microsoft SQL Server</li>
              <li>No SQL Database (Cosmos DB)</li>
              <li>Microsoft Azure</li>
              <li>Amazon Web Services</li>
            </ul>
          </div>
          <br />
          <div
            data-aos='fade-up'
            data-aos-offset='40'
            className={clsx(commonStyles.regularTextContainer, classes.regularTextContainer)}
          >
            <strong>
              Please let us know how we can help you by sending a request for a
              quotation or sending us a direct message via chat. <br />
              <br />
              Talk to you soon!
            </strong>
          </div>
        </div>
      </PageContent>
    </div>
  );
};


export default withTheme(withStyles(styles)(WebDevelopment));
