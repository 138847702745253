import React, {useEffect} from 'react';
import { Carousel, OurServices, OurProcess } from '../components';
import { withTheme, withStyles } from '@material-ui/core/styles';
import {getAppInsights} from '../components/common/AppInsights';
const styles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down( 'md')]: {
      display: 'unset'
    },
  }
});

const Body = (props) => {
  useEffect(() => {
   
    let appInsights = getAppInsights();
    appInsights.trackEvent({ name: 'HomeRender', time: Date.now() });
    appInsights.startTrackPage('Home');

    return () => {
      appInsights.stopTrackPage('Home');
    }
  });
  const { classes } = props;
  return (
    <div className={classes.container}>
      <Carousel />
      <OurServices />
      <OurProcess />
    </div>
  );
};

export default withTheme(withStyles(styles)(Body));
