import React from 'react';
import OurProcessStyles from './ourprocess.module.css';
import Button from '../common/Button';
import { Link } from 'react-router-dom';
import SvgImage from '../common/SvgImage';
import SvgProcess from '../../assets/process.svg';

import { withTheme, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const styles = theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 10,
      display: 'unset'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      width: '85%',
    },
  },
  leftBox: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      paddingLeft: "5%",
      marginLeft: 30
    },
    [theme.breakpoints.between('md','lg')]: {
    paddingLeft: '15%'
    },
  },
  leftBoxTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 'large',
      textAlign: 'left',
      paddingRight: 0,
      height: 50
    },
    [theme.breakpoints.between('md','lg')]: {
      fontSize: 'large',
      height: 45
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 'xx-large',
    },
  },
  leftBoxContent: {
    [theme.breakpoints.down('sm')]: {
      paddingRight: 10,
      fontSize: 'small'
    },
    [theme.breakpoints.between('md','lg')]: {
      fontSize: 'small'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 'medium'
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: 'large'
    },
  },
  rightBox: {
    [theme.breakpoints.down('sm')]: {
      width: '75%',
      marginLeft: '15%',
      marginTop: 40,
      marginBottom: 40
    },
  },
});

const Process = (props) => {
  const { classes } = props;

  return (
    <div data-aos='flip-left' className={clsx(OurProcessStyles.container, classes.container)}>
      <div
        className={clsx(OurProcessStyles.leftBox, classes.leftBox)}
        style={{ alignItems: 'flex-start' }}
      >
        <div data-aos='zoom-in-down' className={clsx(OurProcessStyles.leftBoxTitle, classes.leftBoxTitle)}>
          Our Process
        </div>
        <div data-aos='zoom-in-down'>
          <div
            className={clsx(OurProcessStyles.leftBoxContent, classes.leftBoxContent)}
            style={{ width: '98%', height: 92 }}
          >
            We commit ourselves to satisfy our clients needs by continously
            delivering working functionalities with built in quality.
          </div>
          <div
            className={OurProcessStyles.webDevButton}
            style={{ paddingLeft: 0, paddingTop: 0 }}
          >
            <Link style={{ textDecoration: 'none' }} to='/OurProcess'>
              <Button text={'Learn more'} />
            </Link>
          </div>
        </div>
      </div>
      <div data-aos='zoom-in-up' className={clsx(OurProcessStyles.rightBox, classes.rightBox)}>
        <SvgImage
          className={OurProcessStyles.svg}
          id='svgWorkNow'
          svg={SvgProcess}
        />
      </div>
    </div>
  );
};

export default withTheme(withStyles(styles)(Process));

