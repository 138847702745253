import React from 'react';
import WebServiceImage from '../../assets/connect.jpg';
import Button from '../common/Button';
import { Link } from 'react-router-dom';
import OurServicesWebServeStyles from './ourservices_webservices.module.css';
import { withTheme, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';


const styles = theme => ({
  rightGreenBox: {
    [theme.breakpoints.down('sm')]: {
      height: 30,
      width: 100,

    },
  },
  leftBox: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    [theme.breakpoints.between('sm', 'md')]: {
      paddingLeft: '5%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      paddingLeft: 'unset'
    },
  },

  centerImage: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      height: '80%',
      width: '90%',
    },
  },
  leftDescriptionHeader: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 'large',
      paddingLeft: 0,
      width: '90%'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      fontSize: 'large',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 'xx-large',
    },
  },
  rightBox: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: '10%'
    },
    [theme.breakpoints.up('md')]: {
      visibility: 'hidden',
      width: 0,
    },
  },
  leftDescriptionText: {
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      // paddingLeft: 30,
      fontSize: 'small'
    },
    [theme.breakpoints.between('md', 'lg')]: {
      paddingLeft: 'unset',
      fontSize: 'small'
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 'medium'
    },
 [theme.breakpoints.up('xl')]: {
      fontSize: 'large'
    },
  },
  webDevButton: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 30,
    },
    [theme.breakpoints.between('sm','md')]: {
      paddingTop: 20,
      paddingLeft: 'unset',
    }
  },
  leftDescriptionContainer: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 30,
      width: '100%'
    },
  },
  rightBoxMobileHidden: {
    [theme.breakpoints.down('sm')]: {
      visibility: 'hidden',
      width: 0
    },
  },
});

const WebServices = (props) => {
  const { classes } = props;
  return (
    <div className={OurServicesWebServeStyles.container}>
      <div className={clsx(OurServicesWebServeStyles.rightBox, classes.rightBox)}>
        <img
          data-aos='zoom-in'
          src={WebServiceImage}
          className={clsx(OurServicesWebServeStyles.centerImage, classes.centerImage)}
        />
        <div
          data-aos='fade-up-left'
          className={clsx(OurServicesWebServeStyles.rightGreenBox, classes.rightGreenBox)}
        ></div>
      </div>
      <div className={clsx(OurServicesWebServeStyles.leftBox, classes.leftBox)}>
        <div className={clsx(OurServicesWebServeStyles.leftDescriptionContainer, classes.leftDescriptionContainer)}>
          <div
            data-aos='fade-right'
            className={clsx(OurServicesWebServeStyles.leftDescriptionHeader, classes.leftDescriptionHeader)}
          >
            Systems Integration
          </div>
          <div data-aos='fade-up'>
            <div className={clsx(OurServicesWebServeStyles.leftDescriptionText, classes.leftDescriptionText)}>
              We know that constant communication is essential. That is why we
              make it efficient by exchanging data without delay with partners,
              suppliers, or other systems so everyone at stake can make
              real-time decisions for an uninterrupted business operation.
            </div>
            <div className={clsx(OurServicesWebServeStyles.webDevButton, classes.webDevButton)}>
              <Link style={{ textDecoration: 'none' }} to='/Integration'>
                <Button text='Learn more' />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={clsx(OurServicesWebServeStyles.rightBox, classes.rightBoxMobileHidden)}>
        <img
          data-aos='zoom-in'
          src={WebServiceImage}
          className={clsx(OurServicesWebServeStyles.centerImage, classes.centerImage)}
        />
        <div
          data-aos='fade-up-left'
          className={clsx(OurServicesWebServeStyles.rightGreenBox, classes.rightGreenBox)}
        ></div>
      </div>
    </div>
  );
};

export default withTheme(withStyles(styles)(WebServices));
